export const PAYMENT_TABS = [
  {
    title: "All",
    status: "",
  },
  {
    title: "Pending requests",
    status: "Pending",
  },
  {
    title: "Processing",
    status: "Processing",
  },
  {
    title: "Completed",
    status: "Completed",
  },
  {
    title: "Declined from admin",
    status: "Declined",
  },
  {
    title: "Cancelled from user",
    status: "Cancelled",
  },
];
