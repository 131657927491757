import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import addIcon2 from "../../assets/addIcon2.png";
import restoreIcon2 from "../../assets/restoreIcon2.png";
import trashIcon from "../../assets/trashIcon.png";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import StyledButton from "../../components/StyledButton";
import TypeSelector from "../../components/TypeSelector";
import { useLoginStore } from "../../store/index.js";
import { tokens } from "../../theme";

function Stickers() {
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const token = useLoginStore((state) => state.token);
  const tableRef = useRef();
  const API_KEY = process.env.REACT_APP_API_KEY;
  const endPoint = "Stickers";
  const [open, setOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState("");
  const [stickerData, setStickerData] = useState({});
  const [type, setType] = useState("Sticker");
  const [page, setPage] = useState(1);
  const pageRef = useRef(1);
  const pageSizeRef = useRef(5);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const urlPost = `${API_KEY}/${endPoint}`;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const options = ["Sticker", "Gif", "Emoji"];
  const [sortDirection] = useState("asc");
  const [file, setFile] = useState(null);

  const fetchData = async () => {
    // Create a URL object
    let url = new URL(`${API_KEY}/${endPoint}`);

    // Create a new URLSearchParams object from the URL search parameters
    let searchParams = new URLSearchParams(url.search);

    // Add a query parameter

    searchParams.append("sortDirection", sortDirection);
    searchParams.append("page", pageRef.current);
    searchParams.append("perPage", pageSizeRef.current);
    searchParams.append("search", search);
    searchParams.append("type", options.indexOf(type) + 1);

    // Update the search property of the URL object with the new search parameters
    url.search = searchParams;
    console.log(url.href);
    try {
      const response = await fetch(url.href, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized request");
        // Redirect the user to the login page
        // window.location.href = '/login';
        return;
      }

      if (!response.ok) {
        // Handle other unsuccessful responses here
        console.log("Request failed:", response.status);
        // window.location.href = '/login';
        return;
      } else {
        const result = await response.json();
        const { stickers, meta } = result.data;
        console.log(result);
        return {
          data: stickers,
          page: meta.page - 1,
          totalCount: meta.total,
        };
      }
    } catch (error) {
      // Handle any other errors that may occur during the API call
      console.error("An error occurred:", error);
      // Perform desired error handling action, such as showing an error message to the user
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStickerData, setType]);

  const handleOnChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const result = await fetchData();
    setStickerData(result.data);
    setPage(result.page);
    setTotalCount(result.totalCount);
    refreshData();
  };

  const handleTypeSelect = (selectedOption) => {
    console.log("Selected option:", selectedOption);
    // Handle the selected option
    setSearch("");
    setType(selectedOption);
    refreshData();
  };

  const handlePageChange = (newPage) => {
    pageRef.current = newPage + 1;
    refreshData();
  };

  const handlePageSizeChange = (newPageSize) => {
    pageSizeRef.current = newPageSize;
    refreshData();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddDialogOpen = () => {
    setStickerData({});
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const openDeleteConfirmationDialog = () => {
    setDeleteDialogOpen(false);
    setConfirmationDialog("Delete");
    setConfirmationDialogOpen(true);
  };

  const openRestoreConfirmationDialog = () => {
    setDeleteDialogOpen(false);
    setConfirmationDialog("Restore");
    setConfirmationDialogOpen(true);
  };

  const refreshData = () =>
    tableRef.current && tableRef.current.onQueryChange();

  const restoreSticker = (rowData) => {
    setConfirmationDialog("Restore");
    setStickerData({
      id: rowData.id,
      type: rowData.type,
      name: rowData.name,
      url: rowData.url,
    });
    setDeleteDialogOpen(true);
  };

  const deleteSticker = (rowData) => {
    setConfirmationDialog("Delete");
    setStickerData({
      id: rowData.id,
      type: rowData.type,
      name: rowData.name,
      url: rowData.url,
    });
    setDeleteDialogOpen(true);
  };

  const addSticker = () => {
    setConfirmationDialog("Add");
    setStickerData({
      name: stickerData.name,
      url: stickerData.url,
      file: stickerData.file,
    });
    handleAddSubmit();
  };

  const editSticker = (rowData) => {
    setStickerData({
      id: rowData.id,
      type: rowData.type,
      name: rowData.name,
      url: rowData.url,
    });
    setOpen(true);
  };

  const handleEditSubmit = () =>
    new Promise((resolve, reject) => {
      //Logic for updating a Sticker on the Database
      fetch(urlPost + "/" + stickerData.id, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: stickerData.id,
          type: stickerData.type,
          name: stickerData.name,
          url: stickerData.url,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve();
          handleClose();
          refreshData();
        });
    });

  // const handleAddSubmit = () =>
  //   new Promise((resolve, reject) => {
  //     //Logic for adding a Sticker on the Database
  //     const formData = new FormData();

  //     // Append fields to the FormData object
  //     formData.append("name", stickerData?.name);
  //     formData.append("type", (options.indexOf(type) + 1).toString());
  //     formData.append("file", file);

  //     fetch(urlPost, {
  //       method: "POST",
  //       headers: {
  //         "Content-type": "multipart/form-data",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: formData,
  //     })
  //       .then((resp) => resp.json())
  //       .then((resp) => {
  //         resolve();
  //         handleAddDialogClose();
  //         refreshData();
  //         // console.log(resp.message)
  //       });
  //   });

  const handleAddSubmit = () => {
    const formData = new FormData();

    // Append fields to the FormData object
    formData.append("name", stickerData?.name);
    formData.append("type", (options.indexOf(type) + 1).toString());
    formData.append("file", file);

    // Display all values of FormData in console.log
    // for (const [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    fetch(urlPost, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        handleAddDialogClose();
        setConfirmationDialogOpen(true);

        refreshData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handelDeleteSubmit = (stickerData) =>
    new Promise((resolve, reject) => {
      //Logic for deleting a Sticker
      fetch(urlPost + "/" + stickerData.id, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve();
          openDeleteConfirmationDialog();
          refreshData();
        });
    });

  const handleRestoreSubmit = (rowData) =>
    new Promise((resolve, reject) => {
      //Logic for restoring a Sticker
      fetch(urlPost + "/Restore/" + rowData.id, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve();
          openRestoreConfirmationDialog();
          refreshData();
        });
    });

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const columns = [
    {
      title: "Type",
      field: "type",
      //   lookup: { Sticker: "Sticker", Gif: "Gif", Emoji: "Emoji" },
    },
    { title: "Name", field: "name" },
    {
      title: "Image",
      field: "url",
      render: (rowData) => (
        <img
          src={rowData.url}
          alt="imageUrl"
          style={{
            maxWidth: "100%",
            width: "80px",
            height: "80px",
            cursor: "pointer",
          }}
        />
      ),
    },
    {
      title: "isDeleted",
      field: "isDeleted",
      lookup: { true: "true", false: "false" },
      editable: "never",
    },
    { title: "Active", field: "active", editable: "never" },
  ];

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <Box sx={{ backgroundColor: colors.primary[600] }}>
        <Box
          m="53px 100px 0px 100px"
          sx={{ backgroundColor: colors.primary[600] }}
        >
          {/* <TypeSelector onClick={selectTypeHandleSelect} /> */}
          <TypeSelector options={options} onSelect={handleTypeSelect} />
          <Header title={`Manage ${type}`} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ flexGrow: 1, marginRight: "15px" }}>
              <SearchBar
                title={`Search ${type}...`}
                onSubmit={handleFormSubmit}
                onChange={handleOnChange}
                value={search}
              />
            </Box>
            <Box sx={{ marginRight: "15px" }}>
              <StyledButton
                title={`Add ${type}`}
                startIcon={<AddBoxOutlinedIcon />}
                onClick={() => handleAddDialogOpen()}
              />
            </Box>
            <Box>
              {/* <DropDown onSelect={selectTypeHandleSelect} /> */}
              {/* <DropDown options={options} onSelect={selectTypeHandleSelect} /> */}
            </Box>
          </Box>
          <Box paddingBottom={"16px"}>
            <MaterialTable
              title="Stickers Table"
              tableRef={tableRef}
              columns={columns}
              data={fetchData}
              page={page}
              totalCount={totalCount}
              onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
              onChangePage={(params) => handlePageChange(params)}
              actions={[
                (rowData) => ({
                  icon: "restore",
                  tooltip: "Restore",
                  onClick: (event, rowData) => {
                    restoreSticker(rowData);
                  },
                  disabled: rowData.isDeleted === false,
                }),
                (rowData) => ({
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    deleteSticker(rowData);
                  },
                  disabled: rowData.isDeleted === true,
                }),
                {
                  icon: `edit`,
                  tooltip: "Edit",
                  onClick: (event, rowData) => editSticker(rowData),
                },
              ]}
              components={{
                Toolbar: (props) => {
                  return (
                    <div style={{ backgroundColor: colors.blueAccent[100] }}>
                      <MTableToolbar {...props} />
                    </div>
                  );
                },
              }}
              options={{
                showTitle: false,
                paging: true,
                toolbar: false,
                actionsColumnIndex: -1,
                addRowPosition: "first",
                maxBodyHeight: "71vh",
                minBodyHeight: "100%",
                columnResizable: true,
                tableLayout: "auto",
                emptyRowsWhenPaging: false,
                loadingType: "overlay",
                // pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
                pageSize: pageSizeRef.current,
                headerStyle: {
                  backgroundColor: colors.blueAccent[100],
                  paddingRight: "2rem",
                  alignItems: "center",
                  color: colors.primary[100],
                  fontWeight: "bold",
                },
                rowStyle: {
                  backgroundColor: colors.secondary[900],
                },
              }}
              // editable={{ isDeletable: rowData => (rowData.isDeleted === true) ? false : true, }}
            />
          </Box>
          {/* The Editing Dialog */}
          <Dialog open={open} onClose={handleClose}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DialogTitle fontWeight={"600"} fontSize={"20px"}>
                Edit {type}
              </DialogTitle>
            </Box>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              >
                <Box sx={{ marginRight: "35px" }}>
                  <Typography marginBottom={"5px"} fontWeight={"600"}>
                    Name
                  </Typography>
                  <TextField
                    sx={{
                      backgroundColor: colors.blueAccent[100],
                      width: "100%",
                    }}
                    size={"small"}
                    value={stickerData.name}
                    onChange={(e) =>
                      setStickerData({ ...stickerData, name: e.target.value })
                    }
                  />
                </Box>
                <Box>
                  <Typography marginBottom={"5px"} fontWeight={"600"}>
                    Url
                  </Typography>
                  <TextField
                    sx={{
                      backgroundColor: colors.blueAccent[100],
                      width: "100%",
                    }}
                    size={"small"}
                    value={stickerData.url}
                    onChange={(e) =>
                      setStickerData({ ...stickerData, url: e.target.value })
                    }
                  />
                </Box>
              </Box>
              {/* <FormControl
                sx={{
                  marginRight: "25px",
                  marginLeft: "25px",
                  width: "100%",
                }}
              >
                <Typography marginBottom={"5px"} fontWeight={"600"}>
                  Type
                </Typography>
                <Select
                  size={"small"}
                  sx={{ backgroundColor: colors.blueAccent[100] }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stickerData.type}
                  onChange={(e) =>
                    setStickerData({ ...stickerData, type: e.target.value })
                  }
                >
                  <MenuItem value={`Sticker`}>Sticker</MenuItem>
                  <MenuItem value={`Gif`}>Gif</MenuItem>
                  <MenuItem value={`Emoji`}>Emoji</MenuItem>
                </Select>
              </FormControl> */}
            </DialogContent>
            <DialogActions
              sx={{
                marginBottom: "25px",
                marginRight: "35px",
              }}
            >
              {/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
              <Box>
                <StyledButton
                  title={`Save`}
                  onClick={() => handleEditSubmit(true)}
                  sx={{
                    padding: "10px 40px",
                    margin: "0px 0px",
                    color: colors.grey[900],
                    backgroundColor: colors.blueAccent[500],
                    "&:hover": {
                      color: colors.grey[900],
                      backgroundColor: colors.blueAccent[900],
                    },
                  }}
                />
              </Box>
            </DialogActions>
          </Dialog>
          {/* The Add Dialog */}
          <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DialogTitle fontWeight={"600"} fontSize={"20px"}>
                Add {type}
              </DialogTitle>
            </Box>
            <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
              <Box>
                <Box
                  sx={{
                    marginBottom: "30px",
                    marginLeft: "25px",
                    marginRight: "25px",
                  }}
                >
                  <Box sx={{ marginRight: "35px" }}>
                    <Typography marginBottom={"5px"} fontWeight={"600"}>
                      Name
                    </Typography>
                    <TextField
                      sx={{
                        backgroundColor: colors.blueAccent[100],
                        width: "100%",
                      }}
                      size={"small"}
                      value={stickerData.name}
                      onChange={(e) =>
                        setStickerData({ ...stickerData, name: e.target.value })
                      }
                    />
                  </Box>
                  {/* <Box>
                    <Typography marginBottom={"5px"} fontWeight={"600"}>
                      Url
                    </Typography>
                    <TextField
                      sx={{
                        backgroundColor: colors.blueAccent[100],
                        width: "100%",
                      }}
                      size={"small"}
                      value={stickerData.url}
                      onChange={(e) =>
                        setStickerData({ ...stickerData, url: e.target.value })
                      }
                    />
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    marginLeft: "25px",
                    marginRight: "25px",
                  }}
                >
                  <input type="file" onChange={handleFileChange} />{" "}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                marginBottom: "25px",
                marginRight: "35px",
              }}
            >
              {/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
              <Box>
                <StyledButton
                  title={`Save`}
                  onClick={() => addSticker()}
                  sx={{
                    padding: "10px 40px",
                    margin: "0px 0px",
                    color: colors.grey[900],
                    backgroundColor: colors.blueAccent[500],
                    "&:hover": {
                      color: colors.grey[900],
                      backgroundColor: colors.blueAccent[900],
                    },
                  }}
                />
              </Box>
            </DialogActions>
          </Dialog>
          {/* The Delete Dialog */}
          <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
            {confirmationDialog === "Delete" ? (
              <DialogTitle
                marginTop={"10px"}
                align="center"
                fontWeight={"600"}
                fontSize={"20px"}
              >
                Delete {type}?
              </DialogTitle>
            ) : (
              <DialogTitle
                marginTop={"10px"}
                align="center"
                fontWeight={"600"}
                fontSize={"20px"}
              >
                Restore {type}?
              </DialogTitle>
            )}
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginY: "0px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              >
                <Typography marginBottom={"5px"} fontWeight={"600"}>
                  {confirmationDialog === "Delete"
                    ? "Are you sure you want to delete"
                    : "Are you sure you want to restore"}
                  <Typography display={"inline"} color={colors.blueAccent[500]}>
                    {" "}
                    {stickerData.name}?{" "}
                  </Typography>
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ marginBottom: "25px", marginX: "35px" }}>
              <Box>
                {confirmationDialog === "Delete" ? (
                  <StyledButton
                    title={`Yes, Delete`}
                    onClick={() => handelDeleteSubmit(stickerData)}
                    sx={{
                      padding: "10px 40px",
                      margin: "0px 0px",
                      color: colors.redAccent[500],
                      backgroundColor: colors.redAccent[900],
                      "&:hover": {
                        color: colors.grey[900],
                        backgroundColor: colors.blueAccent[900],
                      },
                    }}
                  />
                ) : (
                  <StyledButton
                    title={`Yes, restore`}
                    onClick={() => handleRestoreSubmit(stickerData)}
                    sx={{
                      padding: "10px 40px",
                      margin: "0px 0px",
                      color: colors.blueAccent[500],
                      backgroundColor: colors.blueAccent[100],
                      "&:hover": {
                        color: colors.grey[900],
                        backgroundColor: colors.blueAccent[900],
                      },
                    }}
                  />
                )}
              </Box>
              <Box>
                {confirmationDialog === "Delete" ? (
                  <StyledButton
                    title={`No, Don't delete`}
                    onClick={() => setDeleteDialogOpen(false)}
                    sx={{
                      padding: "10px 30px",
                      margin: "0px 0px",
                      color: colors.redAccent[900],
                      backgroundColor: colors.redAccent[500],
                      "&:hover": {
                        color: colors.grey[900],
                        backgroundColor: colors.blueAccent[900],
                      },
                    }}
                  />
                ) : (
                  <StyledButton
                    title={`No, Don't restore`}
                    onClick={() => setDeleteDialogOpen(false)}
                    sx={{
                      padding: "10px 30px",
                      margin: "0px 0px",
                      color: colors.blueAccent[100],
                      backgroundColor: colors.blueAccent[500],
                      "&:hover": {
                        color: colors.grey[900],
                        backgroundColor: colors.blueAccent[900],
                      },
                    }}
                  />
                )}
              </Box>
            </DialogActions>
          </Dialog>
          {/* The Confirmation Dialog */}
          <Dialog
            open={confirmationDialogOpen}
            onClose={handleConfirmationDialogClose}
          >
            <DialogTitle marginTop={"8px"} align="center" fontWeight={"600"}>
              {confirmationDialog === "Delete" ? (
                <img src={trashIcon} alt="" width={"80vw"} />
              ) : confirmationDialog === "Add" ? (
                <img src={addIcon2} alt="" width={"80vw"} />
              ) : (
                <img src={restoreIcon2} alt="" width={"80vw"} />
              )}
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginY: "0px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              >
                <Typography
                  marginBottom={"5px"}
                  fontWeight={"600"}
                  fontSize={"20px"}
                >
                  {confirmationDialog === "Add"
                    ? `${confirmationDialog}ed Successfully`
                    : `${confirmationDialog}d Successfully`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {confirmationDialog === "Add"
                  ? `${stickerData.name} is successfully ${confirmationDialog}ed`
                  : `${stickerData.name} is successfully ${confirmationDialog}d.`}
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
                marginX: "35px",
              }}
            >
              <Box>
                <StyledButton
                  title={`Done`}
                  onClick={() => setConfirmationDialogOpen(false)}
                  sx={{
                    padding: "1vh 8vw",
                    margin: "0px 0px",
                    color: colors.primary[900],
                    backgroundColor: colors.blueAccent[500],
                    "&:hover": {
                      color: colors.grey[900],
                      backgroundColor: colors.blueAccent[900],
                    },
                  }}
                />
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    );
  }
}
export default Stickers;
