import { Button, Box, useTheme } from '@mui/material';
import { tokens } from '../theme';

const StyledButton = ({ title, ...other }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<Box>
			<Button
				variant='contained'
				sx={{
					padding: '9px 9px',
					margin: '0px 0px',
					height: '45px',
					borderRadius: '8px',
					color: colors.grey[900],
					backgroundColor: colors.blueAccent[500],
					'&:hover': {
						color: colors.grey[900],
						backgroundColor: colors.blueAccent[900],
					},
				}}
				{...other}
			>
				{title}
			</Button>
		</Box>
	);
};

export default StyledButton;
