import {  useTheme } from '@mui/material';
import { tokens } from '../theme';
import './components-styles.css';

const SearchBar = ({ title, onSubmit, onChange, value }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<>
			<div class='search'>
				<form
					// eslint-disable-next-line tailwindcss/no-custom-classname
					class='search-form'
					onSubmit={onSubmit}
					style={{
						backgroundColor: colors.primary[900],
					}}
				>
					<input
						type='text'
						placeholder={title}
						value={value} // <--- change value prop to search state variable
						onChange={onChange}
						style={{
							backgroundColor: colors.primary[900],
							color: colors.primary[700],
							fontFamily: 'Poppins',
							fontWeight: '400',
							fontSize: '16px',
							lineHeight: '120%',
						}}
					/>
					<input type='submit' value='' />
				</form>
			</div>
		</>
	);
};

export default SearchBar;
