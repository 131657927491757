import { Box, useTheme } from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { useEffect, useRef, useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import DropDown from "../../components/DropDown";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import { useLoginStore } from "../../store/index.js";
import { tokens } from "../../theme";

const Users = () => {
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const tableRef = useRef();
  const API_KEY = process.env.REACT_APP_API_KEY;
  const endPoint = "Users";
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageRef = useRef(1);
  const pageSizeRef = useRef(10);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = useLoginStore((state) => state.token);
  const [userData, setUsersData] = useState({});
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const options = [
    { label: "Descending", value: "desc" },
    { label: "Ascending", value: "asc" },
  ];

  const roles = ["Select action", "Block User", "Hide User"];

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        `${API_KEY}/${endPoint}?sortDirection=${sortDirection}&page=${pageRef.current}&perPage=${pageSizeRef.current}&search=${search}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized request");
        // Redirect the user to the login page
        window.location.href = "/login";
        return;
      }

      if (!response.ok) {
        // Handle other unsuccessful responses here
        console.log("Request failed:", response.status);
        window.location.href = "/login";
        return;
      } else {
        const result = await response.json();
        const { users, meta } = result.data;
        // console.log(result);
        return {
          data: users,
          page: meta.page - 1,
          totalCount: meta.total,
        };
      }
    } catch (error) {
      // Handle any other errors that may occur during the API call
      console.error("An error occurred:", error);
      // Perform desired error handling action, such as showing an error message to the user
    }
  }, [API_KEY, endPoint, pageRef, pageSizeRef, search, sortDirection, token]);

  useEffect(() => {
    fetchData().then((result) => {
      if (result) {
        setUsersData(result.data);
        setPage(result.page);
        setTotalCount(result.totalCount);
      }
    });
  }, [fetchData, setUsersData, setPage, setTotalCount]);

  const handleOnChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const result = await fetchData();
    setUsersData(result.data);
    console.log(userData);
    setPage(result.page);
    setTotalCount(result.totalCount);
    refreshData();
  };

  const handleSelect = async (sortOption) => {
    // event.preventDefault();
    const result = await fetchData();
    setSortDirection(sortOption);
    setUsersData(result.data);
    setPage(result.page);
    setTotalCount(result.totalCount);
    refreshData();
  };

  const handlePageChange = (newPage) => {
    pageRef.current = newPage + 1;
    refreshData();
  };

  const handlePageSizeChange = (newPageSize) => {
    pageSizeRef.current = newPageSize;
    refreshData();
  };

  const userActionHandler = (event_value, row_id) => {
    console.log("Event handler and row id : ", event_value, row_id);
  };

  const refreshData = () =>
    tableRef.current && tableRef.current.onQueryChange();

  const columns = [
    {
      title: "Avatar",
      field: "avatar",
      render: (rowData) => {
        return (
          <img
            src={rowData.avatar || "https://via.placeholder.com/128"}
            alt=""
            style={{
              height: "64px",
              width: "64px",
              cursor: "pointer",
              borderRadius: "50%",
              border: "1px solid #000",
            }}
          />
        );
      },
    },
    { title: "Username", field: "username", flex: 1 },
    { title: "Name", field: "name", flex: 1 },
    {
      title: "Age",
      field: "dateOfBirth",
      type: "datetime",
      render: (rowData) => {
        // calculate age from dateOfBirth
        const birthDate = new Date(rowData.dateOfBirth);
        const ageInMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageInMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        // return age as string
        return age.toString();
      },
    },
    { title: "Email", field: "email", flex: 1 },
    { title: "Country", field: "country", type: "datetime", flex: 1 },
    {
      title: "Actions",
      field: "actions",
      render: (rowData) => (
        <select
          className="cursor-pointer"
          placeholder="Select User Options"
          value={rowData.role}
          onChange={(e) => userActionHandler(e.target.value, rowData.id)}
        >
          {roles.map((role) => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
      ),
    },
  ];
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <Box sx={{ backgroundColor: colors.primary[600] }}>
        <Box
          m="60px 100px 0px 100px"
          sx={{ backgroundColor: colors.primary[600] }}
        >
          <Header title="Manage Users" />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ flexGrow: 1, marginRight: "15px" }}>
              <SearchBar
                title="Search Users..."
                onSubmit={handleFormSubmit}
                onChange={handleOnChange}
                value={search}
              />
            </Box>
            <Box sx={{ marginRight: "15px" }}>
              <DropDown
                options={options}
                onSelect={handleSelect}
                title={"Name"}
              />
            </Box>
          </Box>
          <Box>
            <MaterialTable
              title="Users Table"
              tableRef={tableRef}
              columns={columns}
              data={fetchData}
              page={page}
              totalCount={totalCount}
              onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
              onChangePage={(params) => handlePageChange(params)}
              components={{
                Toolbar: (props) => {
                  return (
                    <div style={{ backgroundColor: colors.blueAccent[100] }}>
                      <MTableToolbar {...props} />
                    </div>
                  );
                },
              }}
              options={{
                showTitle: false,
                paging: true,
                toolbar: false,
                actionsColumnIndex: -1,
                addRowPosition: "first",
                maxBodyHeight: "71vh",
                minBodyHeight: "100%",
                columnResizable: true,
                tableLayout: "auto",
                emptyRowsWhenPaging: false,
                loadingType: "overlay",
                // pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
                // pageSize: pageSizeRef.current,
                headerStyle: {
                  backgroundColor: colors.blueAccent[100],
                  paddingRight: "2rem",
                  alignItems: "center",
                  color: colors.primary[100],
                  fontWeight: "bold",
                },
                rowStyle: {
                  backgroundColor: colors.secondary[900],
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
};
export default Users;
