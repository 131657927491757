import { useRef, useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import {
	Box,
	Typography,
	MenuItem,
	Select,
	useTheme,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	DialogActions,
} from '@mui/material';
import { MTableToolbar } from 'material-table';
import MaterialTable from 'material-table';
import { tokens } from '../../theme';
import SearchBar from '../../components/SearchBar';
import StyledButton from '../../components/StyledButton';
import trashIcon from '../../assets/trashIcon.png';
import restoreIcon2 from '../../assets/restoreIcon2.png';
import addIcon2 from '../../assets/addIcon2.png';
import Header from '../../components/Header';
import { useLoginStore } from '../../store';
import DropDown from '../../components/DropDown';

const Admins = () => {
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const tableRef = useRef();
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'admins';
	const [open, setOpen] = useState(false);
	const token = useLoginStore((state) => state.token);
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState('');
	const [adminsData, setAdminsData] = useState({});
	const [page, setPage] = useState(1);
	const pageRef = useRef(1);
	const pageSizeRef = useRef(10);
	const [totalCount, setTotalCount] = useState(0);
	const [search, setSearch] = useState('');
	const urlPost = `${API_KEY}/${endPoint}`;
	const [sortDirection, setSortDirection] = useState('');

	const sortingOptions = [
		{ label: 'Descending', value: 'desc' },
		{ label: 'Ascending', value: 'asc' },
	];

	const fetchData = useCallback(async () => {
		try {
		  const response = await fetch(
			`${API_KEY}/${endPoint}?sortDirection=${sortDirection}&page=${pageRef.current}&perPage=${pageSizeRef.current}&search=${search}`,
			{
			  method: 'GET',
			  headers: {
				'Content-type': 'application/json',
				Authorization: `Bearer ${token}`,
			  },
			}
		  );
	  
		  if (response.status === 401) {
			// Handle 401 Unauthorized error
			console.log('Unauthorized request');
			// Redirect the user to the login page
			window.location.href = '/login';
			return;
		  }
	  
		  if (!response.ok) {
			// Handle other unsuccessful responses here
			console.log('Request failed:', response.status);
			window.location.href = '/login';
			return;
		  } else {
			const result = await response.json();
			const { admins, meta } = result.data;
			console.log(result);
			return {
			  data: admins,
			  page: meta.page - 1,
			  totalCount: meta.total,
			};
		  }
		} catch (error) {
		  // Handle any other errors that may occur during the API call
		  console.error('An error occurred:', error);
		  // Perform desired error handling action, such as showing an error message to the user
		}
	  }, [API_KEY, endPoint, sortDirection, pageRef, pageSizeRef, search, token]);
	  
	  useEffect(() => {
		fetchData();
	  }, [fetchData, setAdminsData]);
	  
	const handleOnChange = (event) => {
		setSearch(event.target.value);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const result = await fetchData();
		setAdminsData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handleSelect = async (sortOption) => {
		// event.preventDefault();
		const result = await fetchData();
		setSortDirection(sortOption);
		setAdminsData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handlePageChange = (newPage) => {
		pageRef.current = newPage + 1;
		refreshData();
	};

	const handlePageSizeChange = (newPageSize) => {
		pageSizeRef.current = newPageSize;
		refreshData();
	};

	const handleAddDialogOpen = () => {
		setAdminsData({});
		setAddDialogOpen(true);
	};

	const handleAddDialogClose = () => {
		setAddDialogOpen(false);
	};

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const handleDeleteDialogClose = () => {
		setDeleteDialogOpen(false);
	};

	const openDeleteConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Delete');
		setConfirmationDialogOpen(true);
	};

	const openRestoreConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Restore');
		setConfirmationDialogOpen(true);
	};

	const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

	const restoreAdmin = (rowData) => {
		setConfirmationDialog('Restore');
		setAdminsData({
			id: rowData.id,
			name: rowData.name,
			username: rowData.username,
			email: rowData.email,
			role: rowData.role,
			isDeleted: rowData.isDeleted,
		});
		setDeleteDialogOpen(true);
	};

	const deleteAdmin = (rowData) => {
		setConfirmationDialog('Delete');
		setAdminsData({
			id: rowData.id,
			name: rowData.name,
			username: rowData.username,
			email: rowData.email,
			role: rowData.role,
		});
		setDeleteDialogOpen(true);
	};

	const addAdmin = () => {
		setConfirmationDialog('Add');
		setAdminsData({
			id: adminsData.id,
			name: adminsData.name,
			username: adminsData.username,
			password: adminsData.password,
			email: adminsData.email,
			role: adminsData.role,
		});
		handleAddSubmit();
		setConfirmationDialogOpen(true);
	};

	const editAdmin = (rowData) => {
		setAdminsData({
			id: rowData.id,
			name: rowData.name,
			username: rowData.username,
			email: rowData.email,
			role: rowData.role,
			// isDeleted: rowData.isDeleted,
		});
		setOpen(true);
	};

	const handleEditSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for updating an Admin on the Database
			fetch(urlPost + '/' + adminsData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: adminsData.id,
					name: adminsData.name,
					username: adminsData.username,
					email: adminsData.email,
					role: adminsData.role,
					// isDeleted: adminsData.isDeleted,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleClose();
					refreshData();
				});
		});

	const handleAddSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for adding an Admin on the Database
			fetch(urlPost, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					// id: adminsData.id,
					name: adminsData.name,
					username: adminsData.username,
					password: adminsData.password,
					email: adminsData.email,
					role: adminsData.role,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleAddDialogClose();
					refreshData();
					// console.log(resp.message)
				});
		});

	const handelDeleteSubmit = (adminsData) =>
		new Promise((resolve, reject) => {
			//Logic for deleting an Admin
			fetch(urlPost + '/' + adminsData.id, {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					openDeleteConfirmationDialog();
					refreshData();
				});
		});

	const handleRestoreSubmit = (rowData) =>
		new Promise((resolve, reject) => {
			//Logic for restoring an Admin
			fetch(urlPost + '/Restore/' + rowData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					openRestoreConfirmationDialog();
					refreshData();
				});
		});

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const columns = [
		{ title: 'Name', field: 'name', cellClassName: 'name-column-cell' },
		{ title: 'Username', field: 'username' },
		{ title: 'Email', field: 'email' },
		{
			title: 'Role',
			field: 'role',
			lookup: { Admin: 'Admin', SuperAdmin: 'Super Admin' },
		},
		{
			title: 'isDeleted',
			field: 'isDeleted',
			lookup: { true: 'true', false: 'false' },
			editable: 'never',
		},
	];

	if (!isLoggedIn) {
		return <Navigate to='/' />;
	} else {
		return (
			<Box
				pt={'10px'}
				sx={{
					backgroundColor: colors.primary[600],
				}}>
				<Box m='60px 100px 0px 100px' sx={{ backgroundColor: colors.primary[600] }}>
					<Header title='Manage Team' />
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ flexGrow: 1, marginRight: '15px' }}>
							<SearchBar title='Search Media...' onSubmit={handleFormSubmit} onChange={handleOnChange} value={search} />
						</Box>
						<StyledButton title={'Add Admins'} onClick={() => handleAddDialogOpen(true)} />
						<Box sx={{ marginRight: '15px' }}>
							<DropDown options={sortingOptions} onSelect={handleSelect} title={'Name'} />
						</Box>
					</Box>

					<Box>
						<MaterialTable
							title='Admins Table'
							tableRef={tableRef}
							columns={columns}
							data={fetchData}
							page={page}
							totalCount={totalCount}
							onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
							onChangePage={(params) => handlePageChange(params)}
							actions={[
								(rowData) => ({
									icon: 'restore',
									tooltip: 'Restore',
									onClick: (event, rowData) => {
										restoreAdmin(rowData);
									},
									disabled: rowData.isDeleted === false,
								}),
								(rowData) => ({
									icon: 'delete',
									tooltip: 'Delete',
									onClick: (event, rowData) => {
										deleteAdmin(rowData);
									},
									disabled: rowData.isDeleted === true,
								}),
								{
									icon: `edit`,
									tooltip: 'Edit',
									onClick: (event, rowData) => editAdmin(rowData),
								},
							]}
							components={{
								Toolbar: (props) => {
									return (
										<div style={{ backgroundColor: colors.blueAccent[100] }}>
											<MTableToolbar {...props} />
										</div>
									);
								},
							}}
							options={{
								showTitle: false,
								paging: true,
								toolbar: false,
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								maxBodyHeight: '71vh',
								minBodyHeight: '100%',
								columnResizable: true,
								tableLayout: 'auto',
								emptyRowsWhenPaging: false,
								loadingType: 'overlay',
								// pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
								// pageSize: pageSizeRef.current,
								headerStyle: {
									backgroundColor: colors.blueAccent[100],
									paddingRight: '2rem',
									alignItems: 'center',
									color: colors.primary[100],
									fontWeight: 'bold',
								},
								rowStyle: {
									backgroundColor: colors.secondary[900],
								},
							}}
						/>
					</Box>
				</Box>
				{/* The Editing Dialog */}
				<Dialog open={open} onClose={handleClose}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<DialogTitle fontWeight={'600'} fontSize={'20px'}>
							Edit Admin
						</DialogTitle>
					</Box>
					<DialogContent>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '30px',
								marginLeft: '25px',
								marginRight: '25px',
							}}>
							<Box sx={{ marginRight: '35px' }}>
								<Typography marginBottom={'5px'} fontWeight={'600'}>
									Name
								</Typography>
								<input
									style={{
										backgroundColor: colors.blueAccent[100],
										width: '100%',
										padding: '10px 0px 10px 14px',
										border: '1px solid #C7C7C7',
										borderRadius: '8px',
									}}
									placeholder='Name'
									size={'small'}
									value={adminsData.name}
									onChange={(e) => setAdminsData({ ...adminsData, name: e.target.value })}
								/>
							</Box>
							<Box>
								<Typography marginBottom={'5px'} fontWeight={'600'}>
									Username
								</Typography>
								<input
									style={{
										backgroundColor: colors.blueAccent[100],
										width: '100%',
										padding: '10px 0px 10px 14px',
										border: '1px solid #C7C7C7',
										borderRadius: '8px',
									}}
									placeholder='Username'
									size={'small'}
									value={adminsData.username}
									onChange={(e) => setAdminsData({ ...adminsData, username: e.target.value })}
								/>
							</Box>
						</Box>
						<Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Email
									</Typography>
									<input
										style={{
											backgroundColor: colors.blueAccent[100],
											width: '100%',
											padding: '10px 0px 10px 14px',
											border: '1px solid #C7C7C7',
											borderRadius: '8px',
										}}
										placeholder='Name'
										size={'small'}
										value={adminsData.email}
										onChange={(e) => setAdminsData({ ...adminsData, email: e.target.value })}
									/>
								</Box>
								<Box>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Role
									</Typography>
									<Select
										size={'small'}
										sx={{
											backgroundColor: colors.blueAccent[100],
											width: '100%',
											border: '1px none #C7C7C7',
											borderRadius: '8px',
										}}
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={adminsData.role}
										onChange={(e) => setAdminsData({ ...adminsData, role: e.target.value })}>
										<MenuItem value={`Admin`}>Admin</MenuItem>
										<MenuItem value={`SuperAdmin`}>Super Admin</MenuItem>
									</Select>
								</Box>
							</Box>
							{/* <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px', marginLeft: '25px', marginRight: '25px' }}>

							</Box> */}
						</Box>
						<FormControl
							sx={{
								marginRight: '25px',
								marginLeft: '25px',
								width: '100%',
							}}></FormControl>
					</DialogContent>
					<DialogActions
						sx={{
							marginBottom: '25px',
							marginRight: '35px',
						}}>
						{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
						<Box>
							<StyledButton
								title={`Save`}
								onClick={() => handleEditSubmit(true)}
								sx={{
									padding: '10px 40px',
									margin: '0px 0px',
									color: colors.grey[900],
									backgroundColor: colors.blueAccent[500],
									'&:hover': {
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[900],
									},
								}}
							/>
						</Box>
					</DialogActions>
				</Dialog>
				{/* The Add Dialog */}
				<Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<DialogTitle fontWeight={'600'} fontSize={'20px'}>
							Add Admin
						</DialogTitle>
					</Box>
					<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
						<Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Name
									</Typography>
									<input
										style={{
											backgroundColor: colors.blueAccent[100],
											width: '100%',
											padding: '10px 0px 10px 14px',
											border: '1px solid #C7C7C7',
											borderRadius: '8px',
										}}
										placeholder='Name'
										size={'small'}
										value={adminsData.name}
										onChange={(e) => setAdminsData({ ...adminsData, name: e.target.value })}
									/>
								</Box>
								<Box>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Username
									</Typography>
									<input
										style={{
											backgroundColor: colors.blueAccent[100],
											width: '100%',
											padding: '10px 0px 10px 14px',
											border: '1px solid #C7C7C7',
											borderRadius: '8px',
										}}
										placeholder='Username'
										size={'small'}
										value={adminsData.username}
										onChange={(e) => setAdminsData({ ...adminsData, username: e.target.value })}
									/>
								</Box>
							</Box>
							<Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}>
									<Box sx={{ marginRight: '35px' }}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Email
										</Typography>
										<input
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '10px 0px 10px 14px',
												border: '1px solid #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Name'
											size={'small'}
											value={adminsData.email}
											onChange={(e) => setAdminsData({ ...adminsData, email: e.target.value })}
										/>
									</Box>
									<Box>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Password
										</Typography>
										<input
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '10px 0px 10px 14px',
												border: '1px solid #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Password'
											size={'small'}
											value={adminsData.password}
											onChange={(e) => setAdminsData({ ...adminsData, password: e.target.value })}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										// justifyContent: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}>
									<Box sx={{justifyContent: 'flex-start'}}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Role
										</Typography>
										<Select
											size={'small'}
											sx={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '0 100%',
												border: '1px none #C7C7C7',
												borderRadius: '8px',
											}}
											labelId='demo-simple-select-label'
											id='demo-simple-select'
											value={adminsData.role}
											onChange={(e) => setAdminsData({ ...adminsData, role: e.target.value })}>
											<MenuItem value={`Admin`}>Admin</MenuItem>
											<MenuItem value={`SuperAdmin`}>Super Admin</MenuItem>
										</Select>
									</Box>
								</Box>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions
						sx={{
							marginBottom: '25px',
							marginRight: '35px',
						}}>
						<Box>
							<StyledButton
								title={`Add admin`}
								onClick={() => addAdmin()}
								sx={{
									padding: '20px 24x',
									margin: '0px 0px',
									color: colors.grey[900],
									backgroundColor: colors.blueAccent[500],
									'&:hover': {
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[900],
									},
								}}
							/>
						</Box>
					</DialogActions>
				</Dialog>
				{/* The Delete Dialog */}
				<Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
					{confirmationDialog === 'Delete' ? (
						<DialogTitle marginTop={'10px'} align='center' fontWeight={'600'} fontSize={'20px'}>
							Delete Admin?
						</DialogTitle>
					) : (
						<DialogTitle marginTop={'10px'} align='center' fontWeight={'600'} fontSize={'20px'}>
							Restore Admin?
						</DialogTitle>
					)}
					<DialogContent>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginY: '0px',
								marginLeft: '25px',
								marginRight: '25px',
							}}>
							<Typography marginBottom={'5px'} fontWeight={'600'}>
								{confirmationDialog === 'Delete'
									? 'Are you sure you want to delete'
									: 'Are you sure you want to restore'}
								<Typography display={'inline'} color={colors.blueAccent[500]}>
									{' '}
									{adminsData.name}?{' '}
								</Typography>
							</Typography>
						</Box>
					</DialogContent>
					<DialogActions sx={{ marginBottom: '25px', marginX: '35px' }}>
						<Box>
							{confirmationDialog === 'Delete' ? (
								<StyledButton
									title={`Yes, Delete`}
									onClick={() => handelDeleteSubmit(adminsData)}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.redAccent[500],
										backgroundColor: colors.redAccent[900],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							) : (
								<StyledButton
									title={`Yes, restore`}
									onClick={() => handleRestoreSubmit(adminsData)}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.blueAccent[500],
										backgroundColor: colors.blueAccent[100],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							)}
						</Box>
						<Box>
							{confirmationDialog === 'Delete' ? (
								<StyledButton
									title={`No, Don't delete`}
									onClick={() => setDeleteDialogOpen(false)}
									sx={{
										padding: '10px 30px',
										margin: '0px 0px',
										color: colors.redAccent[900],
										backgroundColor: colors.redAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							) : (
								<StyledButton
									title={`No, Don't restore`}
									onClick={() => setDeleteDialogOpen(false)}
									sx={{
										padding: '10px 30px',
										margin: '0px 0px',
										color: colors.blueAccent[100],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							)}
						</Box>
					</DialogActions>
				</Dialog>
				{/* The Confirmation Dialog */}
				<Dialog open={confirmationDialogOpen} onClose={handleConfirmationDialogClose}>
					<DialogTitle marginTop={'8px'} align='center' fontWeight={'600'}>
						{confirmationDialog === 'Delete' ? (
							<img src={trashIcon} alt='' width={'80vw'} />
						) : confirmationDialog === 'Add' ? (
							<img src={addIcon2} alt='' width={'80vw'} />
						) : (
							<img src={restoreIcon2} alt='' width={'80vw'} />
						)}
					</DialogTitle>
					<DialogContent>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginY: '0px',
								marginLeft: '25px',
								marginRight: '25px',
							}}>
							<Typography marginBottom={'5px'} fontWeight={'600'} fontSize={'20px'}>
								{confirmationDialog === 'Add'
									? `${confirmationDialog}ed Successfully`
									: `${confirmationDialog}d Successfully`}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
							{confirmationDialog === 'Add'
								? `${adminsData.name} is successfully ${confirmationDialog}ed`
								: `${adminsData.name} is successfully ${confirmationDialog}d.`}
						</Box>
					</DialogContent>
					<DialogActions
						sx={{
							display: 'flex',
							justifyContent: 'center',
							marginBottom: '25px',
							marginX: '35px',
						}}>
						<Box>
							<StyledButton
								title={`Done`}
								onClick={() => setConfirmationDialogOpen(false)}
								sx={{
									padding: '1vh 8vw',
									margin: '0px 0px',
									color: colors.primary[900],
									backgroundColor: colors.blueAccent[500],
									'&:hover': {
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[900],
									},
								}}
							/>
						</Box>
					</DialogActions>
				</Dialog>
			</Box>
		);
	}
};

export default Admins;
