import { useRef, useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import {
	Box,
	useTheme,
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	DialogActions,
	FormControl,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import SearchBar from '../../components/SearchBar';
import StyledButton from '../../components/StyledButton';
import { useLoginStore } from '../../store';

function Reports() {
	const { isLoggedIn } = useLoginStore();
	const tableRef = useRef();
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'Reports';
	const [page, setPage] = useState(1);
	// const [pageSize, setPageSize] = useState(10);
	const pageRef = useRef(1);
	const pageSizeRef = useRef(10);
	const [open, setOpen] = useState(false);
	const [reportData, setReportData] = useState({});
	const urlPost = `${API_KEY}/${endPoint}`;
	const [totalCount, setTotalCount] = useState(0);
	const [search, setSearch] = useState('');
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const token = useLoginStore((state) => state.token);

	const fetchData = useCallback(async () => {
		try {
		  const response = await fetch(`${API_KEY}/${endPoint}`, {
			method: 'GET',
			headers: {
			  'Content-type': 'application/json',
			  Authorization: `Bearer ${token}`,
			},
		  });
	  
		  if (response.status === 401) {
			// Handle 401 Unauthorized error
			console.log('Unauthorized request');
			// Redirect the user to the login page
			// window.location.href = '/login';
			return;
		  }
	  
		  if (!response.ok) {
			// Handle other unsuccessful responses here
			console.log('Request failed:', response.status);
			// window.location.href = '/login';
			return;
		  } else {
			const result = await response.json();
			const { reports, meta } = result.data;
			console.log(result);
			return {
			  data: reports,
			  page: meta.page - 1,
			  totalCount: meta.total,
			};
		  }
		} catch (error) {
		  // Handle any other errors that may occur during the API call
		  console.error('An error occurred:', error);
		  // Perform desired error handling action, such as showing an error message to the user
		}
	  }, [API_KEY, endPoint, token]);
	  
	  useEffect(() => {
		fetchData();
	  }, [setReportData, fetchData]);
	  
	const handleOnChange = (event) => {
		setSearch(event.target.value);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const result = await fetchData();
		setReportData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handlePageChange = (newPage) => {
		pageRef.current = newPage + 1;
		refreshData();
	};

	const handlePageSizeChange = (newPageSize) => {
		pageSizeRef.current = newPageSize;
		refreshData();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const editReport = (rowData) => {
		setReportData({
			id: rowData.id,
			userId: rowData.userId,
			mediaId: rowData.mediaId,
			reason: rowData.reason,
			isResolved: rowData.isResolved,
		});
		setOpen(true);
	};

	const handleEditSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for updating a report on the Database
			fetch(urlPost + '/' + reportData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: reportData.id,
					userId: reportData.userId,
					mediaId: reportData.mediaId,
					reason: reportData.reason,
					isResolved: reportData.isResolved,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleClose();
					refreshData();
				});
		});

	const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

	const columns = [
		{ field: 'userId', title: 'User ID' },
		{ field: 'mediaId', title: 'Media ID', flex: 1 },
		{ field: 'reason', title: 'Reason for Report', flex: 1 },
		{
			field: 'isResolved',
			title: 'isResolved',
			flex: 1,
			lookup: { true: 'True', false: 'False' },
		},
	];

	if (!isLoggedIn) {
		return <Navigate to='/' />;
	} else {
		return (
			<Box pt={'10px'} sx={{ backgroundColor: colors.primary[600], }}>
				<Box m='60px 100px 0px 100px' sx={{ backgroundColor: colors.primary[600] }}>
					<Header title='Manage Reports' />
					<Box sx={{ flexGrow: 1, marginRight: '15px' }}>
						<SearchBar title='Search Reports...' onSubmit={handleFormSubmit} onChange={handleOnChange} value={search} />
					</Box>
					<Box>
						<MaterialTable
							title='Reports Table'
							tableRef={tableRef}
							columns={columns}
							data={fetchData}
							page={page}
							totalCount={totalCount}
							onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
							onChangePage={(params) => handlePageChange(params)}
							actions={[
								(rowData) => ({
									icon: 'edit',
									tooltip: 'Edit',
									onClick: (event, rowData) => {
										editReport(rowData);
									},
								}),
							]}
							components={{
								Toolbar: (props) => {
									return (
										<div style={{ backgroundColor: colors.blueAccent[100] }}>
											<MTableToolbar {...props} />
										</div>
									);
								},
							}}
							options={{
								showTitle: false,
								paging: true,
								toolbar: false,
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								maxBodyHeight: '60vh',
								minBodyHeight: '100%',
								columnResizable: true,
								tableLayout: 'auto',
								emptyRowsWhenPaging: false,
								loadingType: 'overlay',
								
								// pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
								// pageSize: pageSizeRef.current,
								headerStyle: {
									backgroundColor: colors.blueAccent[100],
									paddingRight: '2rem',
									alignItems: 'center',
									color: colors.primary[100],
									fontWeight: 'bold',
								},
								rowStyle: {
									backgroundColor: colors.secondary[900],
								},
							}}
						/>
					</Box>
					{/* The Editing Dialog */}
					<Dialog open={open} onClose={handleClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Edit report
							</DialogTitle>
						</Box>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										User Id
									</Typography>
									<TextField
										sx={{
											backgroundColor: colors.blueAccent[100],
											width: '15vw',
											marginBottom: '10px',
										}}
										size={'small'}
										value={reportData.userId}
										disabled={true}
										onChange={(e) => setReportData({ ...reportData, userId: e.target.value })}
									/>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Media Id
									</Typography>
									<TextField
										sx={{
											backgroundColor: colors.blueAccent[100],
											width: '15vw',
											marginBottom: '10px',
										}}
										size={'small'}
										value={reportData.mediaId}
										disabled={true}
										onChange={(e) => setReportData({ ...reportData, media: e.target.value })}
									/>
								</Box>
								<Box>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Report reason
									</Typography>
									<TextField
										sx={{
											backgroundColor: colors.blueAccent[100],
											width: '15vw',
											marginBottom: '10px',
										}}
										size={'small'}
										value={reportData.reason}
										disabled={true}
										onChange={(e) => setReportData({ ...reportData, reason: e.target.value })}
									/>
									<Box>
										<FormControl
											sx={{
												marginRight: '25px',
												// marginLeft: '25px',
												width: '15vw',
											}}>
											<Typography marginBottom={'5px'} fontWeight={'600'}>
												isResolved
											</Typography>
											<Select
												size={'small'}
												sx={{ backgroundColor: colors.blueAccent[100] }}
												labelId='demo-simple-select-label'
												id='demo-simple-select'
												value={reportData.isResolved}
												onChange={(e) =>
													setReportData({
														...reportData,
														isResolved: e.target.value,
													})
												}>
												<MenuItem value={true}>true</MenuItem>
												<MenuItem value={false}>false</MenuItem>
											</Select>
										</FormControl>
									</Box>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}>
							<Box>
								<StyledButton
									title={`Save`}
									onClick={() => handleEditSubmit(true)}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
				</Box>
			</Box>
		);
	}
}
export default Reports;
