import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Team from './scenes/admins';
import Users from './scenes/users';
import Stickers from './scenes/stickers';
import Content from './scenes/media';
import Reports from './scenes/reports';
import Login from './scenes/login';
import AppVersions from './scenes/app-versions';
import Profile from './scenes/profile';
import Filters from './scenes/filters';
import Gifts from './scenes/gifts';
import Events from './scenes/events';
import Payments from './scenes/payments';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode, tokens } from './theme';
import { useLoginStore } from './store/index';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ManagePosts from './scenes/manageposts';

function App() {
	const [theme, colorMode] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);
	// const token = useLoginStore((state) => state.token);
	const location = useLocation();
	// const { setLoggedIn, setToken, setTheme } = useLoginStore();
	const navigate = useNavigate();
	const colors = tokens(theme.palette.mode);
	const hideSidebar = location.pathname === '/login' || location.pathname === '/';
	const hideTopbar = location.pathname === '/login' || location.pathname === '/';

	useEffect(() => {
		const storedToken = useLoginStore.getState().token;
		const storedMode = useLoginStore.getState().mode;
	
		if (storedToken) {
			useLoginStore.setState({
				isLoggedIn: true,
				token: storedToken,
				mode: storedMode || 'light',
			});
		} else {
			navigate('/login');
		}
	}, [navigate]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className='app' style={{ backgroundColor: colors.primary[600] }}>
					{!hideSidebar && <Sidebar isSidebar={isSidebar} className='app' />}
					<main className='content'>
						{!hideTopbar && <Topbar setIsSidebar={setIsSidebar} />}
						<Routes>
							<Route path='/' element={<Dashboard />} />

							<Route path='/login' element={<Login />} />

							<Route path='/dashboard' element={<Dashboard />} />
							<Route path='/admins' element={<Team />} />
							<Route path='/users' element={<Users />} />
							<Route path='/manage-posts' element={<ManagePosts />} />
							<Route path='/media' element={<Content />} />
							<Route path='/stickers' element={<Stickers />} />
							<Route path='/reports' element={<Reports />} />
							<Route path='/app-versions' element={<AppVersions />} />
							<Route path='/profile' element={<Profile />} />
							<Route path='/filters' element={<Filters />} />
							<Route path='/gifts' element={<Gifts />} />
							<Route path='/events' element={<Events />} />
							<Route path='/payments' element={<Payments />} />
						</Routes>
					</main>
				</div>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
