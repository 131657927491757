import { useRef, useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import {
	Box,
	Button,
	useTheme,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	DialogActions,
	FormControl,
	Typography,
} from '@mui/material';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import SearchBar from '../../components/SearchBar';
import StyledButton from '../../components/StyledButton';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import trashIcon from '../../assets/trashIcon.png';
import restoreIcon2 from '../../assets/restoreIcon2.png';
import addIcon2 from '../../assets/addIcon2.png';
import './index.css';
import { useLoginStore } from '../../store/index.js';
import DropDown from '../../components/DropDown';

function Gifts() {
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const tableRef = useRef();
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'Gifts';
	const [open, setOpen] = useState(false);
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState('');
	const [giftData, setGiftData] = useState({});
	const [page, setPage] = useState(1);
	const pageRef = useRef(1);
	const pageSizeRef = useRef(10);
	const [totalCount, setTotalCount] = useState(0);
	const urlPost = `${API_KEY}/${endPoint}`;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const token = useLoginStore((state) => state.token);
	const [openMap, setOpenMap] = useState({});
	const [search, setSearch] = useState('');

	const [sortDirection, setSortDirection] = useState('');

	const options = [
		{ label: 'Descending', value: 'desc' },
		{ label: 'Ascending', value: 'asc' },
	];

	const fetchData = useCallback(async () => {
		try {
		  const response = await fetch(
			`${API_KEY}/${endPoint}?sortDirection=${sortDirection}&page=${pageRef.current}&perPage=${pageSizeRef.current}&search=${search}`,
			{
			  method: 'GET',
			  headers: {
				'Content-type': 'application/json',
				Authorization: `Bearer ${token}`,
			  },
			}
		  );
	  
		  if (response.status === 401) {
			// Handle 401 Unauthorized error
			console.log('Unauthorized request');
			// Redirect the user to the login page
			window.location.href = '/login';
			return;
		  }
	  
		  if (!response.ok) {
			// Handle other unsuccessful responses here
			console.log('Request failed:', response.status);
			window.location.href = '/login';
			return;
		  } else {
			const result = await response.json();
			const { gifts, meta } = result.data;
			console.log(result);
			return {
			  data: gifts,
			  page: meta.page - 1,
			  totalCount: meta.total,
			};
		  }
		} catch (error) {
		  // Handle any other errors that may occur during the API call
		  console.error('An error occurred:', error);
		  // Perform desired error handling action, such as showing an error message to the user
		}
	  }, [API_KEY, endPoint, sortDirection, pageRef, pageSizeRef, search, token]);
	  
	  useEffect(() => {
		fetchData();
	  }, [setGiftData, setSortDirection, fetchData]);
	  
	const handleOnChange = (event) => {
		setSearch(event.target.value);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const result = await fetchData();
		setGiftData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handleSelect = async (sortOption) => {
		// event.preventDefault();
		const result = await fetchData();
		setSortDirection(sortOption);
		setGiftData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handlePageChange = (newPage) => {
		pageRef.current = newPage + 1;
		refreshData();
	};

	const handlePageSizeChange = (newPageSize) => {
		pageSizeRef.current = newPageSize;
		refreshData();
	};
	const handleClose = () => {
		setOpen(false);
	};

	// const handleGifInputChange = (e) => {
	// 	const file = e.target.files[0];
	// 	setGiftData({ ...giftData, gif: file });
	// };

	// const handleGifFieldClick = () => {
	// 	document.getElementById('gifInput').click();
	// };

	const handleImageInputChange = (e) => {
		const file = e.target.files[0];
		setGiftData({ ...giftData, file });
	  };
	  
	const handleImageFieldClick = () => {
		document.getElementById('imageInput').click();
	};

	const handleStartDateChange = (date) => {
		setGiftData({ ...giftData, startDate: date });
	};

	const handleEndDateChange = (date) => {
		setGiftData({ ...giftData, endDate: date });
	};

	const handleAddDialogOpen = () => {
		setGiftData({});
		setAddDialogOpen(true);
	};

	const handleAddDialogClose = () => {
		setAddDialogOpen(false);
	};

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const handleDeleteDialogClose = () => {
		setDeleteDialogOpen(false);
	};

	const openDeleteConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Delete');
		setConfirmationDialogOpen(true);
	};

	const openRestoreConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Restore');
		setConfirmationDialogOpen(true);
	};

	const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

	const restoreGift = (rowData) => {
		setConfirmationDialog('Restore');
		setGiftData({
			id: rowData.id,
			name: rowData.name,
			price: rowData.price,
			image: rowData.imageUrl,
			startDate: rowData.startDate,
			endDate: rowData.endDate,
		});
		setDeleteDialogOpen(true);
	};

	const deleteGift = (rowData) => {
		setConfirmationDialog('Delete');
		setGiftData({
			id: rowData.id,
			name: rowData.name,
			price: rowData.price,
			image: rowData.imageUrl,
			startDate: rowData.startDate,
			endDate: rowData.endDate,
		});
		setDeleteDialogOpen(true);
	};

	const addGift = () => {
		setConfirmationDialog('Add');
		setGiftData({
			id: giftData.id,
			name: giftData.name,
			price: giftData.price,
			file: giftData.file,
			startDate: giftData.startDate,
			endDate: giftData.endDate,
		});
		handleAddSubmit();
		setConfirmationDialogOpen(true);
	};

	const editGift = (rowData) => {
		setGiftData({
			id: rowData.id,
			name: rowData.name,
			price: rowData.price,
			image: rowData.imageUrl,
			startDate: rowData.startDate,
			endDate: rowData.endDate,
		});
		setOpen(true);
	};

	const handleEditSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for updating a Sticker on the Database
			fetch(urlPost + '/' + giftData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: giftData.id,
					name: giftData.name,
					price: giftData.price,
					image: giftData.imageUrl,
					startDate: giftData.startDate,
					endDate: giftData.endDate,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleClose();
					refreshData();
				});
		});

	const handleAddSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for adding a Gift on the Database
			fetch(urlPost, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: giftData.id,
					name: giftData.name,
					price: giftData.price,
					file: giftData.file,
					startDate: giftData.startDate,
					endDate: giftData.endDate,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					console.log(resp);
					resolve();
					handleAddDialogClose();
					refreshData();
					// console.log(resp.message)
				});
		});

	const handelDeleteSubmit = (giftData) =>
		new Promise((resolve, reject) => {
			//Logic for deleting a Gift
			fetch(urlPost + '/' + giftData.id, {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					openDeleteConfirmationDialog();
					refreshData();
				});
		});

	const handleRestoreSubmit = (rowData) =>
		new Promise((resolve, reject) => {
			//Logic for restoring a Gift
			fetch(urlPost + '/Restore/' + rowData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					openRestoreConfirmationDialog();
					refreshData();
				});
		});

	const columns = [
		{ title: 'Name', field: 'name' },
		{ title: 'price', field: 'price' },
		{
			title: 'Image',
			field: 'imageUrl',
			render: (rowData) => {
				const handleClick = () => {
					setOpenMap((prevOpenMap) => ({
						...prevOpenMap,
						[rowData.id]: true,
					}));
				};

				const handleClose = () => {
					setOpenMap((prevOpenMap) => ({
						...prevOpenMap,
						[rowData.id]: false,
					}));
				};

				const isOpen = openMap[rowData.id];

				return (
					<>
						<img
							src={rowData.imageUrl}
							alt='imageUrl'
							style={{ maxWidth: '100px', cursor: 'pointer' }}
							onClick={handleClick}
						/>
						<Dialog open={isOpen} onClose={handleClose}>
							<DialogTitle>{rowData.name}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									<img src={rowData.imageUrl} alt='imageUrl' style={{ maxWidth: '100%', cursor: 'pointer' }} />
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									style={{
										padding: '8px 8px',
										margin: '8px 8px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</>
				);
			},
		},
		{
			title: 'isDeleted',
			field: 'isDeleted',
			lookup: { true: 'true', false: 'false' },
			editable: 'never',
		},
		{ title: 'Active', field: 'isActive', editable: 'never' },
	];
	if (!isLoggedIn) {
		return <Navigate to='/' replace />;
	} else {
		return (
			<Box sx={{ backgroundColor: colors.primary[600] }}>
				<Box m='60px 100px 0px 100px' sx={{ backgroundColor: colors.primary[600] }}>
					<Header title='Manage Gifts' />
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ flexGrow: 1, marginRight: '15px' }}>
							<SearchBar title='Search Media...' onSubmit={handleFormSubmit} onChange={handleOnChange} value={search} />
						</Box>
						<Box>
							<StyledButton
								title={`Add Gift`}
								startIcon={<AddBoxOutlinedIcon />}
								onClick={() => handleAddDialogOpen()}
							/>
						</Box>
						<Box sx={{ marginRight: '15px' }}>
							<DropDown options={options} onSelect={handleSelect} title={'Price'} />
						</Box>
					</Box>
					<Box>
						<MaterialTable
							title='Gifts Table'
							tableRef={tableRef}
							columns={columns}
							data={fetchData}
							page={page}
							totalCount={totalCount}
							onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
							onChangePage={(params) => handlePageChange(params)}
							actions={[
								(rowData) => ({
									icon: 'restore',
									tooltip: 'Restore',
									onClick: (event, rowData) => {
										restoreGift(rowData);
									},
									disabled: rowData.isDeleted === false,
								}),
								(rowData) => ({
									icon: 'delete',
									tooltip: 'Delete',
									onClick: (event, rowData) => {
										deleteGift(rowData);
									},
									disabled: rowData.isDeleted === true,
								}),
								{
									icon: `edit`,
									tooltip: 'Edit',
									onClick: (event, rowData) => editGift(rowData),
								},
							]}
							components={{
								Toolbar: (props) => {
									return (
										<div style={{ backgroundColor: colors.blueAccent[100] }}>
											<MTableToolbar {...props} />
										</div>
									);
								},
							}}
							options={{
								showTitle: false,
								paging: true,
								toolbar: false,
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								maxBodyHeight: '60vh',
								minBodyHeight: '100%',
								columnResizable: true,
								tableLayout: 'auto',
								emptyRowsWhenPaging: false,
								loadingType: 'overlay',
								// pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
								// pageSize: pageSizeRef.current,
								headerStyle: {
									backgroundColor: colors.blueAccent[100],
									paddingRight: '2rem',
									alignItems: 'center',
									color: colors.primary[100],
									fontWeight: 'bold',
								},
								rowStyle: {
									backgroundColor: colors.secondary[900],
								},
							}}
						/>
					</Box>
					{/* The Editing Dialog */}
					<Dialog open={open} onClose={handleClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Edit Gift
							</DialogTitle>
						</Box>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Name
									</Typography>
									<TextField
										sx={{ backgroundColor: colors.blueAccent[100], width: '100%' }}
										size={'small'}
										value={giftData.name}
										onChange={(e) => setGiftData({ ...giftData, name: e.target.value })}
									/>
								</Box>
								<Box>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Price
									</Typography>
									<TextField
										sx={{ backgroundColor: colors.blueAccent[100], width: '100%' }}
										size={'small'}
										value={giftData.price}
										onChange={(e) => setGiftData({ ...giftData, price: e.target.value })}
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box
									sx={{
										flex: 1,
									}}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Image
									</Typography>
									<input
										className='imageUploader-gift'
										style={{
											backgroundColor: colors.blueAccent[100],
											width: '100%',
											padding: '10px 50px 10px 14px',
											border: '1px dashed #C7C7C7',
											borderRadius: '8px',
										}}
										placeholder='Image PNG or JPEG'
										size={'small'}
										value={giftData.image}
										onClick={handleImageFieldClick}
										onChange={(e) => setGiftData({ ...giftData, image: e.target.value })}
									/>
									{/* Handling the upload of the Image */}
									<input
										id='imageInput'
										accept='image/png, image/jpeg'
										type='file'
										style={{ display: 'none' }}
										onChange={handleImageInputChange}
									/>
								</Box>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px', width: '100%' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Start Date
									</Typography>
									<DatePicker
										className='dateInputPicker-gift'
										selected={giftData.startDate}
										value={giftData.startDate}
										onChange={handleStartDateChange}
										placeholderText='Start Date'
										dateFormat='yyyy-MM-dd'
										size='small'
										minDate={new Date()}
									/>
								</Box>
								<Box sx={{width: '100%'}}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										End Date
									</Typography>
									<DatePicker
										className='dateInputPicker-gift'
										selected={giftData.endDate}
										value={giftData.endDate}
										onChange={handleEndDateChange}
										placeholderText='End Date'
										dateFormat='yyyy-MM-dd'
										size='small'
									/>
								</Box>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									// marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<img src={giftData.image} alt='' style={{ width: '100px' }} />
							</Box>
							<FormControl
								sx={{
									marginRight: '25px',
									marginLeft: '25px',
									width: '100%',
								}}></FormControl>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}>
							{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
							<Box>
								<StyledButton
									title={`Save`}
									onClick={() => handleEditSubmit(true)}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Add Dialog */}
					<Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Add Gift
							</DialogTitle>
						</Box>
						<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}>
									<Box sx={{ marginRight: '35px' }}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Name
										</Typography>
										<input
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '10px 0px 10px 14px',
												border: '1px solid #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Name'
											size={'small'}
											value={giftData.name}
											onChange={(e) => setGiftData({ ...giftData, name: e.target.value })}
										/>
									</Box>
									<Box>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Price
										</Typography>
										<input
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '10px 0px 10px 14px',
												border: '1px solid #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Price'
											size={'small'}
											value={giftData.price}
											onChange={(e) => setGiftData({ ...giftData, price: e.target.value })}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}>
									<Box
										sx={{
											flex: 1,
										}}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Image
										</Typography>
										<input
											className='imageUploader-gift'
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '10px 0px 10px 14px',
												border: '1px dashed #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Image PNG or JPEG'
											size={'small'}
											value={giftData.file ? giftData.file.name : '' }
											onClick={handleImageFieldClick}
											onChange={(e) => setGiftData({ ...giftData, file: e.target.value })}
										/>
										{/* Handling the upload of the Image */}
										<input
											id='imageInput'
											accept='image/png, image/jpeg'
											type='file'
											style={{ display: 'none' }}
											onChange={handleImageInputChange}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}>
									<Box sx={{ marginRight: '35px' }}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Start Date
										</Typography>
										<DatePicker
											className='dateInputPicker-gift'
											selected={giftData.startDate}
											onChange={handleStartDateChange}
											placeholderText='Start Date'
											dateFormat='yyyy-MM-dd'
											size='small'
											minDate={new Date()}
										/>
									</Box>
									<Box>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											End Date
										</Typography>
										<DatePicker
											className='dateInputPicker-gift'
											selected={giftData.endDate}
											onChange={handleEndDateChange}
											placeholderText='End Date'
											dateFormat='yyyy-MM-dd'
											size='small'
										/>
									</Box>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}>
							{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
							<Box>
								<StyledButton
									title={`Add gift`}
									onClick={() => addGift()}
									sx={{
										padding: '20px 24x',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Delete Dialog */}
					<Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
						{confirmationDialog === 'Delete' ? (
							<DialogTitle marginTop={'10px'} align='center' fontWeight={'600'} fontSize={'20px'}>
								Delete Gift?
							</DialogTitle>
						) : (
							<DialogTitle marginTop={'10px'} align='center' fontWeight={'600'} fontSize={'20px'}>
								Restore Gift?
							</DialogTitle>
						)}
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Typography marginBottom={'5px'} fontWeight={'600'}>
									{confirmationDialog === 'Delete'
										? 'Are you sure you want to delete'
										: 'Are you sure you want to restore'}
									<Typography display={'inline'} color={colors.blueAccent[500]}>
										{' '}
										{giftData.name}?{' '}
									</Typography>
								</Typography>
							</Box>
						</DialogContent>
						<DialogActions sx={{ marginBottom: '25px', marginX: '35px' }}>
							<Box>
								{confirmationDialog === 'Delete' ? (
									<StyledButton
										title={`Yes, Delete`}
										onClick={() => handelDeleteSubmit(giftData)}
										sx={{
											padding: '10px 40px',
											margin: '0px 0px',
											color: colors.redAccent[500],
											backgroundColor: colors.redAccent[900],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								) : (
									<StyledButton
										title={`Yes, restore`}
										onClick={() => handleRestoreSubmit(giftData)}
										sx={{
											padding: '10px 40px',
											margin: '0px 0px',
											color: colors.blueAccent[500],
											backgroundColor: colors.blueAccent[100],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								)}
							</Box>
							<Box>
								{confirmationDialog === 'Delete' ? (
									<StyledButton
										title={`No, Don't delete`}
										onClick={() => setDeleteDialogOpen(false)}
										sx={{
											padding: '10px 30px',
											margin: '0px 0px',
											color: colors.redAccent[900],
											backgroundColor: colors.redAccent[500],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								) : (
									<StyledButton
										title={`No, Don't restore`}
										onClick={() => setDeleteDialogOpen(false)}
										sx={{
											padding: '10px 30px',
											margin: '0px 0px',
											color: colors.blueAccent[100],
											backgroundColor: colors.blueAccent[500],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								)}
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Confirmation Dialog */}
					<Dialog open={confirmationDialogOpen} onClose={handleConfirmationDialogClose}>
						<DialogTitle marginTop={'8px'} align='center' fontWeight={'600'}>
							{confirmationDialog === 'Delete' ? (
								<img src={trashIcon} alt='' width={'80vw'} />
							) : confirmationDialog === 'Add' ? (
								<img src={addIcon2} alt='' width={'80vw'} />
							) : (
								<img src={restoreIcon2} alt='' width={'80vw'} />
							)}
						</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Typography marginBottom={'5px'} fontWeight={'600'} fontSize={'20px'}>
									{confirmationDialog === 'Add'
										? `${confirmationDialog}ed Successfully`
										: `${confirmationDialog}d Successfully`}
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
								{confirmationDialog === 'Add'
									? `${giftData.name} is successfully ${confirmationDialog}ed`
									: `${giftData.name} is successfully ${confirmationDialog}d.`}
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '25px',
								marginX: '35px',
							}}>
							<Box>
								<StyledButton
									title={`Done`}
									onClick={() => setConfirmationDialogOpen(false)}
									sx={{
										padding: '1vh 8vw',
										margin: '0px 0px',
										color: colors.primary[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
				</Box>
			</Box>
		);
	}
}
export default Gifts;
