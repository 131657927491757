import { useState, useEffect, useCallback } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { tokens } from '../../theme';
import FlagIcon from '@mui/icons-material/Flag';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Header from '../../components/Header';
import StatBox from '../../components/StatBox';
import { useLoginStore } from '../../store';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import BarChart from './chart';

const Dashboard = () => {
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const mode = useLoginStore((state) => state.mode);
	const API_KEY = process.env.REACT_APP_API_KEY;
	const reportsEndPoint = 'Reports';
	const dashboardEndPoint = 'Analytics/Overview';
	const [page] = useState(1);
	const [pageSize] = useState(10);
	// const [reportData, setReportData] = useState({});
	const [dashboardData, setDashboardData] = useState({});
	const token = useLoginStore((state) => state.token);

	const fetchDashboardData = useCallback(async () => {
		const response = await fetch(`${API_KEY}/${dashboardEndPoint}`, {
		  headers: {
			'Content-type': 'application/json',
			Authorization: `Bearer ${token}`,
		  },
		});
		const result = await response.json();
		console.log(result.data);
		console.log(result);
		if (result.data) {
		  setDashboardData({
			users: result.data.users,
			media: result.data.media,
			reports: result.data.reports,
			gifts: result.data.gifts,
			stickers: result.data.stickers,
			appVersions: result.data.appVersions,
		  });
		} else {
		  // Log the user out by setting the token to empty
		  useLoginStore.setState({ token: '' });
		  // Perform any additional logout actions if necessary
		}
	  }, [API_KEY, dashboardEndPoint, token, setDashboardData]);
	  
	  useEffect(() => {
		fetchDashboardData();
	  }, [fetchDashboardData]);

	const fetchReportsData = async () => {
		try {
			const response = await fetch(`${API_KEY}/${reportsEndPoint}?&page=${page}&perPage=${pageSize}`, {
				method: 'GET',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 401) {
				// Handle 401 Unauthorized error
				console.log('Unauthorized request');
				// Redirect the user to the login page
				// window.location.href = '/login';
				return;
			}

			if (!response.ok) {
				// Handle other unsuccessful responses here
				console.log('Request failed:', response.status);
				// window.location.href = '/login';
				return;
			} else {
				const result = await response.json();
				const { reports, meta } = result.data;
				console.log(result);
				return {
					data: reports,
					page: meta.page - 1,
					totalCount: meta.total,
				};
			}
		} catch (error) {
			// Handle any other errors that may occur during the API call
			console.error('An error occurred:', error);
			// Perform desired error handling action, such as showing an error message to the user
		}
	};

	const columns = [
		{ field: 'userId', title: 'User ID' },
		{ field: 'reason', title: 'Reason', flex: 1 },
		{
			field: 'isResolved',
			title: 'isResolved',
			flex: 1,
			lookup: { true: 'True', false: 'False' },
		},
	];

	if (!isLoggedIn) {
		return <Navigate to='/login' state={theme} />;
	} else {
		return (
			<Box m='60px 100px 0px 100px'>
				{/* HEADER */}
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Header title='Dashboard' />
				</Box>

				{/* Boxes of statistics */}
				<Box display='grid' gridAutoRows='90px' columnGap='16px' rowGap={'30px'}>
					{/* ROW 1 */}
					<Box
						gridColumn='span 3'
						backgroundColor={mode === 'light' ? '#EBECF5' : colors.primary[900]}
						display='flex'
						alignItems='center'
						justifyContent='center'
						borderRadius={'8px'}>
						<StatBox
							title={dashboardData.reports}
							gridTemplateColumns='repeat(12, 1fr)'
							subtitle='Reports Posted'
							textColor={'#5448B2'}
							icon={<FlagIcon sx={{ color: '#5448B2', fontSize: '50px' }} />}
						/>
					</Box>
					<Box
						gridColumn='span 3'
						backgroundColor={mode === 'light' ? 'rgba(218, 165, 83, 0.19)' : colors.primary[900]}
						display='flex'
						alignItems='center'
						justifyContent='center'
						borderRadius={'8px'}>
						<StatBox
							title={dashboardData.media}
							subtitle='Media Posted'
							textColor={'#DAA553'}
							icon={<MmsOutlinedIcon sx={{ color: '#DAA553', fontSize: '50px' }} />}
						/>
					</Box>
					<Box
						gridColumn='span 3'
						backgroundColor={mode === 'light' ? '#d7edfc' : colors.primary[900]}
						display='flex'
						alignItems='center'
						justifyContent='center'
						borderRadius={'8px'}>
						<StatBox
							title={dashboardData.users}
							subtitle='Users'
							textColor={'#468DBF'}
							icon={<PersonAddIcon sx={{ color: '#468DBF', fontSize: '50px' }} />}
						/>
					</Box>
					<Box
						gridColumn='span 3'
						backgroundColor={mode === 'light' ? '#E7F4F3' : colors.primary[900]}
						display='flex'
						alignItems='center'
						justifyContent='center'
						borderRadius={'8px'}>
						<StatBox
							title={dashboardData.gifts}
							subtitle='Gifts'
							textColor={'#0E9384'}
							icon={<CardGiftcardIcon sx={{ color: '#0E9384', fontSize: '50px' }} />}
						/>
					</Box>

					{/* ROW 2 */}
					<Box display={'flex'} gridColumn={'span 12'}>
						<Box flex='1' marginRight='10px'>
							<Box
								mb={'10px'}
								// p='	0px 10px'
								display='flex '
								justifyContent='center'
								alignItems='center'
								backgroundColor={colors.primary[900]}
								borderRadius={'8px'}>
								<Box>
									<Typography mt={'20px'} fontSize={'normal'} variant='h5' fontWeight='600' color={colors.grey[100]}>
										Overview
									</Typography>
									<Box p={'20px'} width={650} height={550}>
										{/* <MyChart labels={myLabels} datasets={myDatasets} /> */}
										<BarChart myData={[dashboardData]} tooltipData={Object.entries(dashboardData)} />
									</Box>
								</Box>
							</Box>
						</Box>
						<Box flex='1'>
							<Box>
								<MaterialTable
									title='Reports Table'
									// tableRef={tableRef}
									columns={columns}
									data={fetchReportsData}
									components={{
										Toolbar: (props) => {
											return (
												<div style={{ backgroundColor: colors.blueAccent[100] }}>
													<MTableToolbar {...props} />
												</div>
											);
										},
									}}
									options={{
										showTitle: false,
										paging: true,
										toolbar: false,
										actionsColumnIndex: -1,
										addRowPosition: 'first',
										maxBodyHeight: '100%',
										minBodyHeight: '100%',
										columnResizable: true,
										tableLayout: 'auto',
										emptyRowsWhenPaging: false,
										loadingType: 'overlay',
										showFirstLastPageButtons: false,
										headerStyle: {
											backgroundColor: colors.blueAccent[100],
											paddingRight: '2rem',
											alignItems: 'center',
											color: colors.primary[100],
											fontWeight: 'bold',
										},
										rowStyle: {
											backgroundColor: colors.secondary[900],
										},
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		);
	}
};

export default Dashboard;
