import { addDays } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Navigate } from "react-router-dom";

import moment from "moment";
import Search from "../../assets/Search.png";
import Calendar from "../../assets/calendar.svg";
import Content from "../../assets/content.svg";
import Cross from "../../assets/cross.svg";
import { useLoginStore } from "../../store";

function ManagePosts() {
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const API_KEY = process.env.REACT_APP_API_KEY;
  const endPoint = "Media/posh-status";
  const pageRef = useRef(1);
  const pageSizeRef = useRef(10);
  const token = useLoginStore((state) => state.token);
  const [search, setSearch] = useState("");
  const [sortDirection] = useState("asc");
  const [postId, setChangePostId] = useState("");
  const [userId, setChangeUserId] = useState("");
  const [postStatus, setPostStatus] = useState("");
  const [aiRange, setAiRange] = useState("");
  const [nudityTag, setNudityTag] = useState("");
  const [violenceTag, setViolenceTag] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [postCards, setPostCards] = useState([]);
  const [postedDate, setPostedDate] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const fetchData = async () => {
    // Create a URL object
    let url = new URL(`${API_KEY}/${endPoint}`);

    // Create a new URLSearchParams object from the URL search parameters
    let searchParams = new URLSearchParams(url.search);

    // Add a query parameter

    searchParams.append("sortDirection", sortDirection);
    searchParams.append("page", pageRef.current);
    searchParams.append("perPage", pageSizeRef.current);
    searchParams.append("search", search);

    if (postStatus) searchParams.append("isPosted", postStatus);
    if (postId) searchParams.append("postId", postId);
    if (userId) searchParams.append("userId", userId);
    if (postedDate) searchParams.append("date", postedDate);
    if (aiRange) searchParams.append("aiRatingScore", aiRange);
    if (nudityTag) searchParams.append("nudityTag", nudityTag);
    if (violenceTag) searchParams.append("violenceTag", violenceTag);
    // Update the search property of the URL object with the new search parameters
    url.search = searchParams;
    console.log(url.href);
    try {
      const response = await fetch(url.href, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized request");
        // Redirect the user to the login page
        // window.location.href = "/login";
        return;
      }

      if (!response.ok) {
        // Handle other unsuccessful responses here
        console.log("Request failed:", response.status);
        // window.location.href = "/login";
        return;
      } else {
        const result = await response.json();
        const { media, meta } = result.data;
        console.log("Manage Posts : ", result);
        let obj = {
          data: media,
          page: meta.page - 1,
          totalCount: meta.total,
        };
        setPostCards(obj.data);
      }
    } catch (error) {
      // Handle any other errors that may occur during the API call
      console.error("An error occurred:", error);
      // Perform desired error handling action, such as showing an error message to the user
    }
  };

  const blockPost = (id, isActive) => {
    console.log("POst id : ", id);
    fetch(API_KEY + "/Media/posh-status/" + id, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        isActive,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        fetchData();
      });
  };

  const blockUser = (id) => {
    console.log("User id : ", id);
    fetch(API_KEY + "/Users/block/" + id, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        isActive: true,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        fetchData();
      });
  };

  const resetHandler = () => {
    setPostStatus("");
    setChangePostId("");
    setChangeUserId("");
    setPostedDate("");
    setAiRange("");
    setNudityTag("");
    setViolenceTag("");
    setSearch("");
  };

  const changePostId = (e) => {
    console.log("Post Id", e);
    setChangePostId(e.target.value);
  };

  const changeUserId = (e) => {
    console.log("User Id", e);
    setChangeUserId(e.target.value);
  };

  const dateChangeHandler = (selectionItem) => {
    setState(selectionItem);
    let startDate = selectionItem[0];
    let finalStartDate = moment(startDate.startDate).format("YYYY-DD-MM");
    let endDate = selectionItem[0];
    let finalEndDate = moment(endDate.endDate).format("YYYY-DD-MM");
    setPostedDate(finalStartDate + " / " + finalEndDate);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleSection = () => {
    setShowSection(!showSection);
    setShowCalendar(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="m-8 rounded-md bg-white pt-6 shadow ">
        <div className="mx-8 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-center gap-3">
            <img src={Content} alt="content media" className="size-8" />
            <h4 className="text-lg font-medium">Visited Video Posts</h4>
          </div>
          <div
            onClick={toggleSection}
            className="cursor-pointer rounded bg-active p-2"
          >
            <img src={Search} alt="content media" className="size-5" />
          </div>
        </div>
        <div className="mt-6 h-px w-full bg-tabColor" />
        <div className="p-8">
          <div className={`overflow-hidden ${showSection ? "h-auto" : "h-0"}`}>
            <div className="flex flex-row items-center justify-between gap-6">
              <div className="flex-1">
                <p className="text-base font-medium text-active">
                  Post Status:
                </p>
                <select
                  className="mt-2 w-full cursor-pointer rounded border border-unfocused px-4 py-3 outline-none focus:border-tab"
                  placeholder="Select User Options"
                  value={postStatus}
                  onChange={(e) => setPostStatus(e.target.value)}
                >
                  <option selected value="Select">
                    Select
                  </option>
                  <option value="POSTED">Active</option>
                  <option value="NOT_POSTED">Blocked</option>
                </select>
              </div>
              <div className="flex-1">
                <p className="text-base font-medium text-active">Post id:</p>
                <input
                  placeholder="Enter Post id"
                  className="mt-2 w-full rounded border border-unfocused px-4 py-3 outline-none focus:border-tab"
                  type="text"
                  onChange={changePostId}
                  value={postId}
                />
              </div>
              <div className="flex-1">
                <p className="text-base font-medium text-active">User id:</p>
                <input
                  placeholder="Enter User id"
                  className="mt-2 w-full rounded border border-unfocused px-4 py-3 outline-none focus:border-tab"
                  type="text"
                  onChange={changeUserId}
                  value={userId}
                />
              </div>
            </div>
            <div className="mt-4 flex flex-row items-center justify-between gap-6">
              <div className="flex-1">
                <p className="text-base font-medium text-active">
                  Posted Date:
                </p>
                <div className="mt-2 flex flex-row items-center justify-between rounded border border-unfocused px-4 py-3">
                  <input
                    className="w-full"
                    type="text"
                    value={postedDate}
                    placeholder="Enter date range"
                    onChange={setPostedDate}
                  />
                  <img
                    onClick={toggleCalendar}
                    src={Calendar}
                    alt="calendar"
                    className="size-5 cursor-pointer"
                  />
                </div>
              </div>
              <div className="flex-1">
                <p className="text-base font-medium text-active">
                  Ai rating Range:
                </p>
                <select
                  className="mt-2 w-full cursor-pointer rounded border border-unfocused px-4 py-3 outline-none focus:border-tab"
                  placeholder="Select User Options"
                  value={aiRange}
                  onChange={(e) => setAiRange(e.target.value)}
                >
                  <option selected value="Select">
                    Select
                  </option>
                  <option value="0%-25%">0%-25%</option>
                  <option value="0%-25%">26%%-50%</option>
                  <option value="0%-25%">51%-80%</option>
                  <option value="0%-25%">81%-90%</option>
                  <option value="0%-25%">91%-100%</option>
                </select>
              </div>
              <div className="flex-1">
                <p className="text-base font-medium text-active">Nudity tag:</p>
                <select
                  className="mt-2 w-full cursor-pointer rounded border border-unfocused px-4 py-3 outline-none focus:border-tab"
                  placeholder="Select User Options"
                  value={nudityTag}
                  onChange={(e) => setNudityTag(e.target.value)}
                >
                  <option selected value="Select">
                    Select
                  </option>
                  <option value="NUDE">NUDE</option>
                  <option value="SAFE">SAFE</option>
                  <option value="SAFE SEXY">SAFE SEXY</option>
                </select>
              </div>
            </div>
            {showCalendar && (
              <div className="absolute z-50 shadow">
                <DateRangePicker
                  onChange={(item) => dateChangeHandler([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                />
                <div className="h-px w-full bg-tabColor" />
                <div className="flex w-full flex-row justify-end bg-white">
                  <p
                    onClick={toggleCalendar}
                    className="m-2 cursor-pointer rounded bg-active p-2 text-white"
                  >
                    Apply Handler
                  </p>
                  <p
                    onClick={toggleCalendar}
                    className="m-2 cursor-pointer rounded bg-active p-2 text-white"
                  >
                    Cancel
                  </p>
                </div>
              </div>
            )}
            <div className="mr-12 mt-4 flex flex-row items-center justify-between">
              <div className="w-1/3">
                <p className="text-base font-medium text-active">
                  Violence Tag:
                </p>
                <select
                  className="mt-2 w-full cursor-pointer rounded border border-unfocused px-4 py-3 outline-none focus:border-tab"
                  placeholder="Select User Options"
                  value={violenceTag}
                  onChange={(e) => setViolenceTag(e.target.value)}
                >
                  <option selected value="Select">
                    Select
                  </option>
                  <option value="VIOLENT">Violent</option>
                  <option value="SAFE">SAFE</option>
                </select>
              </div>
            </div>
            <div className="mt-5 flex w-fit flex-row items-center justify-center gap-4">
              <div
                onClick={fetchData}
                className="flex cursor-pointer flex-row items-center gap-3 rounded border border-tab bg-active px-3 py-2.5"
              >
                <img src={Search} alt="search" className="size-4" />
                <p className="font-normal text-white">Search</p>
              </div>
              <div
                onClick={resetHandler}
                className="flex cursor-pointer flex-row items-center gap-3 rounded border border-unfocused bg-white px-3 py-2.5"
              >
                <img src={Cross} alt="search" className="size-4" />
                <p className="font-normal text-tabColor">Reset</p>
              </div>
            </div>
            <div className="mt-6 h-px w-full border-t border-dashed border-hollow" />
          </div>
          <div className="mt-8 grid grid-cols-4 gap-4">
            {postCards?.map((post, index) => (
              <div className="col-span-1 rounded bg-card p-3 text-center shadow-md">
                <p className="font-normal text-grey">
                  Nudity Tag : {post?.poshDetectResult?.nudityTag}
                </p>
                <p className="font-normal text-grey">
                  Violence Tag : {post?.poshDetectResult?.violenceTag}
                </p>
                <p className="font-normal text-grey">
                  {post?.poshDetectResult?.detectId ?? "null"}
                </p>
                <p
                  title={post?.id}
                  className="mt-6 cursor-pointer font-normal text-grey"
                >
                  Post Id: {post?.id?.toString().substring(0, 10) + ".."}
                </p>
                <p className="font-normal text-grey">
                  {moment(post?.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                </p>
                <div className="flex flex-row items-center justify-center gap-3">
                  <h2 className="text-base font-medium text-grey">
                    Post Status:{" "}
                  </h2>
                  <select
                    className={`mt-2 w-fit cursor-pointer rounded ${
                      post?.poshDetectResult?.isPosted
                        ? "bg-green"
                        : "bg-inactive"
                    } p-2 text-white outline-none`}
                    placeholder="Select User Options"
                    onChange={(e) =>
                      blockPost(post?.id, !post?.poshDetectResult?.isPosted)
                    }
                  >
                    {post?.poshDetectResult?.isPosted ? (
                      <>
                        <option selected value="Active">
                          Active
                        </option>
                        <option value="Block">Block</option>
                      </>
                    ) : (
                      <>
                        <option selected value="Block">
                          Block
                        </option>
                        <option value="Active">Active</option>
                      </>
                    )}
                  </select>
                </div>
                <video controls className="my-2 h-[40vh] w-full object-cover">
                  <source src={post.originalUrl} type="video/mp4" />
                </video>
                <div className="mt-4 flex flex-row items-center justify-center gap-1">
                  <h2 className=" font-semibold text-grey">Author Name : </h2>
                  <h2 className=" cursor-pointer font-medium text-[#5867dd] hover:text-[#5868ddc4]">
                    {post?.user?.name}
                  </h2>
                </div>
                <div className="flex flex-row items-center justify-center gap-3">
                  <h2 className="text-sm font-semibold text-grey">
                    Author Status:{" "}
                  </h2>
                  <select
                    className="mt-2 w-fit cursor-pointer rounded bg-green p-2 text-white outline-none"
                    placeholder="Select User Options"
                    onChange={(e) => blockUser(post?.user?.id)}
                  >
                    <option selected value="Select">
                      Active
                    </option>
                    <option value="0%-25%">Block</option>
                    <option value="0%-25%">Hide User</option>
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default ManagePosts;
