import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useName = create((set) => ({
	isCollapsed: false,
	setIsCollapsed: () => set((state) => ({ isCollapsed: !state.isCollapsed })),
	// userName: 'islam',
}));

export const useLoginStore = create(
	persist(
		(set) => ({
			isLoggedIn: false,
			name: '',
			token: '',
			username: '',
			mode: 'light',
			setMode: (mode) => set(() => ({ mode })),
			setLoggedIn: (isLoggedIn) => set(() => ({ isLoggedIn })),
			setName: (name) => set(() => ({ name })),
			setUsername: (username) => set(() => ({ username })),
			setToken: (token) => set(() => ({ token })),
		}),
		{
			name: 'loginStore', // this key is used to identify the data in localStorage/sessionStorage
			getStorage: () => sessionStorage, // use sessionStorage instead of localStorage
			jsonify: true, // serialize the state as JSON
			whitelist: ['mode'], // persist only the mode value
		}
	)
);

export const useSelectedStore = create((set) => ({
	selected: 'Dashboard',
	setSelected: (selected) => set(() => ({ selected })),
}));
