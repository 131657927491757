import { useEffect, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, TextField, Typography, useTheme, InputAdornment, Link } from '@mui/material';
import { Formik } from 'formik';
import { tokens } from '../../theme';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import profileIcon from '../../assets/Profile.png';
import StyledButton from '../../components/StyledButton';
import { useLoginStore } from '../../store';

const Profile = () => {
	const endPoint = 'Auth/Profile';
	const API_KEY = process.env.REACT_APP_API_KEY;
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const token = useLoginStore((state) => state.token);
	const [isEditing, setIsEditing] = useState(false);
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const [profileData, setProfileData] = useState({});
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const checkoutSchema = yup.object().shape({
		name: yup.string().required('required'),
		username: yup.string().required('required'),
		email: yup.string().email('invalid email').required('required'),
	});

	const fetchData = useCallback(async () => {
		try {
		  const response = await fetch(`${API_KEY}/${endPoint}`, {
			method: 'GET',
			headers: {
			  'Content-type': 'application/json',
			  Authorization: `Bearer ${token}`,
			},
		  });
	  
		  if (response.status === 401) {
			// Handle 401 Unauthorized error
			console.log('Unauthorized request');
			// Redirect the user to the login page
			window.location.href = '/login';
			return;
		  }
	  
		  if (!response.ok) {
			// Handle other unsuccessful responses here
			console.log('Request failed:', response.status);
			window.location.href = '/login';
			return;
		  } else {
			const data = await response.json();
			console.log(data);
			return data;
		  }
		} catch (error) {
		  // Handle any other errors that may occur during the API call
		  console.error('An error occurred:', error);
		  // Perform desired error handling action, such as showing an error message to the user
		}
	  }, [API_KEY, endPoint, token]);
	  
	  useEffect(() => {
		fetchData();
	  }, [fetchData]);
	  

	const editTheData = (profileData) =>
		new Promise((resolve, reject) => {
			//Logic for updating ptofile on the Database
			fetch(`${API_KEY}/${endPoint}`, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					name: profileData.name,
					username: profileData.username,
					email: profileData.email,
					phone: profileData.phone,
					country: profileData.country,
					city: profileData.city,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
				});
		});

		  

	const openEditMode = () => {
		// console.log(profileData);
		console.log('edit button clicked');
		setIsEditing(true);
	};

	const handleFormSubmit = (values) => {
		console.log('save button clicked');
		setIsEditing(false);
		editTheData(profileData);
	};

	if (!isLoggedIn) {
		return <Navigate to='/' />;
	} else {
		return (
			<Box
				m='60px 100px 0px 100px'
				sx={{
					backgroundColor: colors.primary[600],
					// paddingBottom: '18vh',
				}}>
				<Box
					sx={{
						backgroundColor: colors.primary[600],
					}}>
					<Header title='Profile' />
				</Box>
				<Box
					sx={{
						// marginX: '60px',
						paddingX: '50px',
						paddingBottom: '20px',
						border: '2px solid #F4F4F4',
						boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.03)',
						borderRadius: '8px',
					}}
					backgroundColor={colors.secondary[900]}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: '25px',
							paddingTop: '30px',
						}}>
						<img src={`${profileIcon}`} alt='' width={'40px'} />
					</Box>
					<Box>
						{isEditing ? (
							//Editing
							<Box>
								<Formik
									initialValues={{
										name: '',
										email: '',
										username: '',
										phone: '',
										country: '',
										city: '',
									}}
									validationSchema={checkoutSchema}>
									{({ errors, touched, handleBlur }) => (
										<form>
											<Box
												display='grid'
												gap='30px'
												rowGap={'20px'}
												gridTemplateColumns='repeat(2, minmax(0, 1fr))'
												sx={{
													'& > div': {
														gridColumn: isNonMobile ? undefined : 'span 4',
													},
												}}>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Name
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={(event) =>
															setProfileData({
																...profileData,
																name: event.target.value,
															})
														}
														value={profileData.name}
														name='name'
														error={!!touched.name && !!errors.name}
														helperText={touched.name && errors.name}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Username
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={(event) =>
															setProfileData({
																...profileData,
																username: event.target.value,
															})
														}
														value={profileData.username}
														name='username'
														error={!!touched.lastName && !!errors.lastName}
														helperText={touched.lastName && errors.lastName}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Email
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='email'
														onBlur={handleBlur}
														onChange={(event) =>
															setProfileData({
																...profileData,
																email: event.target.value,
															})
														}
														value={profileData.email}
														name='email'
														error={!!touched.lastName && !!errors.email}
														helperText={touched.email && errors.email}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Phone Number
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='tel'
														onBlur={handleBlur}
														onChange={(event) =>
															setProfileData({
																...profileData,
																phone: event.target.value,
															})
														}
														value={profileData.phone}
														name='phone'
														error={!!touched.phone && !!errors.phone}
														helperText={touched.phone && errors.phone}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Country
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={(event) =>
															setProfileData({
																...profileData,
																country: event.target.value,
															})
														}
														value={profileData.country}
														name='country'
														error={!!touched.country && !!errors.country}
														helperText={touched.country && errors.country}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														City
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={(event) =>
															setProfileData({
																...profileData,
																city: event.target.value,
															})
														}
														value={profileData.city}
														name='city'
														error={!!touched.city && !!errors.city}
														helperText={touched.city && errors.city}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
											</Box>
											<Box display='flex' justifyContent='end' mt='20px'>
												<StyledButton title={`Save`} onClick={() => handleFormSubmit(profileData)} />
											</Box>
										</form>
									)}
								</Formik>
							</Box>
						) : (
							//Not Editing
							<>
								<Formik validationSchema={checkoutSchema}>
									{({ errors, touched, handleBlur, handleChange }) => (
										<form>
											<Box
												display='grid'
												gap='30px'
												rowGap={'20px'}
												gridTemplateColumns='repeat(2, minmax(0, 1fr))'
												sx={{
													'& > div': {
														gridColumn: isNonMobile ? undefined : 'span 4',
													},
												}}>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Name
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														value={profileData.name} // Use defaultValue instead of value
														name='name'
														disabled
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Username
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.username}
														name='username'
														error={!!touched.lastName && !!errors.lastName}
														helperText={touched.lastName && errors.lastName}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Email
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='email'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.email}
														name='email'
														error={!!touched.lastName && !!errors.email}
														helperText={touched.email && errors.email}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Phone Number
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='tel'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.phone}
														name='phone'
														error={!!touched.phone && !!errors.phone}
														helperText={touched.phone && errors.phone}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Country
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.country}
														name='country'
														error={!!touched.country && !!errors.country}
														helperText={touched.country && errors.country}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														City
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.city}
														name='city'
														error={!!touched.city && !!errors.city}
														helperText={touched.city && errors.city}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Role
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='text'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.role}
														name='role'
														error={!!touched.accessLevel && !!errors.accessLevel}
														helperText={touched.accessLevel && errors.accessLevel}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
													/>
												</Box>
												<Box>
													<Typography marginBottom={'5px'} fontWeight={'600'}>
														Password
													</Typography>
													<TextField
														fullWidth
														variant='outlined'
														type='password'
														onBlur={handleBlur}
														onChange={handleChange}
														value={profileData.password}
														name='password'
														error={!!touched.password && !!errors.password}
														helperText={touched.password && errors.password}
														disabled={true}
														sx={{ gridColumn: 'span 2' }}
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<Link
																		href='/change-password'
																		color={colors.blueAccent[500]}
																		sx={{ textDecoration: 'none' }}>
																		Change Password
																	</Link>
																</InputAdornment>
															),
														}}
													/>
												</Box>
											</Box>
											<Box display='flex' justifyContent='end' mt='20px'>
												<StyledButton title={`Edit Profile`} onClick={() => openEditMode()} />
											</Box>
										</form>
									)}
								</Formik>
							</>
						)}
					</Box>
				</Box>
			</Box>
		);
	}
};

export default Profile;
