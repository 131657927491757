import { useContext } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext, tokens } from "../../theme";
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import EmojiSymbolsOutlinedIcon from "@mui/icons-material/EmojiSymbolsOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import ToggleOffRoundedIcon from "@mui/icons-material/ToggleOffRounded";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PaymentIcon from "@mui/icons-material/Payment";
import { useName } from "../../store/index";
import seezittLogoLight from "../../assets/seezittLogoLight.png";
import seezittLogoDark from "../../assets/seezittLogoDark.png";
import { useSelectedStore } from "../../store";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[900],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography fontSize="small">{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isCollapsed = useName((state) => state.isCollapsed);
  // const [selected, setSelected] = useState('');
  const selected = useSelectedStore((state) => state.selected);
  const setSelected = useSelectedStore((state) => state.setSelected);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        zIndex: "1002",
      }}
    >
      <Box
        sx={{
          // flex: '0 0 auto',
          height: "inherit",

          "& .pro-sidebar-inner": {
            //the sidebar itself
            background: `${colors.blueAccent[100]} !important`,
            paddingBottom: "40px",
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            color: `${colors.grey[100]} !important`,
            paddingX: "5px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },

          "& .pro-menu-item.active .pro-inner-item": {
            color: `${colors.grey[900]} !important`,
            backgroundColor: `${colors.blueAccent[500]}`,
            borderRadius: "10px",
          },

          "& .pro-sidebar.collapsed": {
            marginRight: "0px",
            paddingRight: "0px",
          },
        }}
      >
        <ProSidebar
          collapsed={isCollapsed}
          style={{
            backgroundColor: colors.blueAccent[100],
            flex: "1 1 0",
            overflow: "auto",
          }}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={"mySidebar"}
        >
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            {!isCollapsed && (
              <Box mb="25px" mt="30px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  {theme.palette.mode === "dark" && (
                    <img
                      alt="dark Logo"
                      src={seezittLogoDark}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {theme.palette.mode === "light" && (
                    <img
                      alt="dark Logo"
                      src={seezittLogoLight}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Box>
              </Box>
            )}
            {isCollapsed && (
              <Box mb="105px" mt="0px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                ></Box>
              </Box>
            )}

            <Box
              paddingX={isCollapsed ? "0px" : "20px"}
              marginX={isCollapsed ? "18px" : "0px"}
            >
              <Item
                title="Dashboard"
                to="/dashboard"
                icon={<BarChartIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[500]}
                sx={{ m: "15px 0 5px 0px" }}
              >
                Data
              </Typography>

              <Item
                title="Manage Team"
                to="/admins"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="App Users"
                to="/users"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Manage Posts"
                to="/manage-posts"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
								title='Manage Media'
								to='/media'
								icon={<PermMediaOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/> */}
              <Item
                title="Manage Stickers"
                to="/stickers"
                icon={<EmojiSymbolsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Manage Reports"
                to="/reports"
                icon={<FlagOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Manage App Versions"
                to="/app-versions"
                icon={<AppSettingsAltIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Manage Filters"
                to="/filters"
                icon={<FilterCenterFocusIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Manage Gifts"
                to="/gifts"
                icon={<CardGiftcardIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Discover Events"
                to="/events"
                icon={<CalendarMonthIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Payment"
                to="/payments"
                icon={<PaymentIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[500]}
                sx={{ m: "15px 0 5px 0px" }}
              >
                Settings
              </Typography>
              <Item
                title={
                  <>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Dark Mode
                      <Box
                        marginLeft={"50px"}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {theme.palette.mode === "dark" ? (
                          <ToggleOnRoundedIcon fontSize="large" />
                        ) : (
                          <ToggleOffRoundedIcon fontSize="large" />
                        )}
                      </Box>
                    </Box>
                  </>
                }
                icon={
                  theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon />
                  ) : (
                    <LightModeOutlinedIcon />
                  )
                }
                to={colorMode.toggleColorMode}
                // selected={selected}
                setSelected={colorMode.toggleColorMode}
              />
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </Box>
  );
};

export default Sidebar;
