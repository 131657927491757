// import React from 'react';
// // import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
// import { Bar } from 'react-chartjs-2';

// import { Chart, registerables } from 'chart.js';

// Chart.register(...registerables);

// export const options = {
// 	responsive: true,
// 	plugins: {
// 		legend: {
// 			position: 'top',
// 		},
// 		title: {
// 			display: true,
// 			text: 'Chart.js Bar Chart',
// 		},
// 	},
// };

// export function MyChart({ labels, datasets }) {
// 	const data = {
// 		labels,
// 		datasets,
// 	};

// 	return <Bar options={options} data={data} />;
// }

import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { tokens } from '../../theme';

// const customTick = () => test;
const customIndexBy = () => <b>test</b>;

export default function BarChart({ myData, tooltipData }) {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<ResponsiveBar
			data={myData}
			groupMode='grouped'
			maxValue={250}
			minValue={0}
			theme={{
				// added
				axis: {
					domain: {
						line: {
							stroke: colors.grey[100],
						},
					},
					legend: {
						text: {
							fill: colors.grey[100],
						},
					},
					ticks: {
						line: {
							stroke: colors.grey[100],
							strokeWidth: 1,
						},
						text: {
							fill: colors.grey[100],
							// fontSize: '5',
							// padding: 5,
						},
					},
				},
				legends: {
					text: {
						fill: colors.grey[100],
					},
				},
				textColor: {
					fill: colors.greenAccent[500],
				},
			}}
			keys={['users', 'media', 'reports', 'gifts', 'stickers', 'appVersions']}
			indexBy={false}
			margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={{ scheme: 'nivo' }}
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: '#38bcb2',
					size: 4,
					padding: 1,
					stagger: true,
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: '#eed312',
					rotation: -45,
					lineWidth: 6,
					spacing: 10,
				},
			]}
			borderColor={{
				from: 'color',
				modifiers: [['darker', '1.6']],
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				renderTick: customIndexBy,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 10,
				tickRotation: 0,
				legend: 'Value', // changed
				legendPosition: 'middle',
				legendOffset: -50,
				// renderTick: customTick,
			}}
			enableLabel={false}
			labelSkipWidth={12}
			labelSkipHeight={12}
			// tooltip={'Data'}
			tooltipLabel={''}
			tooltip={(event) => {
				// const { id, value } = event;
				return (
					<div color={colors.grey[100]}>
						{tooltipData.map(([itemKey, itemValue]) => (
							<div key={itemKey}>
								{itemKey}: {itemValue}
							</div>
						))}
					</div>
				);
			}}
			labelTextColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			legends={[
				{
					dataFrom: 'keys',
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: 'left-to-right',
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: 'hover',
							style: {
								itemOpacity: 1,
							},
						},
					],
				},
			]}
			role='application'
			barAriaLabel={function (e) {
				return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
			}}
		/>
	);
}

// export default BarChart;
