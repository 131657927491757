import { Typography, Box, Container } from "@mui/material";
// import { tokens } from '../theme';
// import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
import React, { useState } from "react";

const TypeSelector = ({ options, onSelect }) => {
  const [selectedType, setSelectedType] = useState("Sticker");
  // const [selectedIndex, setSelectedIndex] = useState(null);

  const handleTypeClick = (type, index) => {
    setSelectedType(type);
    // setSelectedIndex(index);
    onSelect(options[index]);
  };

  return (
    <Box sx={{ marginBottom: "40px", marginTop: "40px", cursor:'pointer' }}>
      <Box sx={{ width: "30%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {options.map((option, index) => (
            <Container disableGutters maxWidth={false}>
              <Typography
                key={option}
                className={`type ${selectedType === option ? "selected" : ""}`}
                onClick={() => handleTypeClick(option, index)}
              >
                {option}
              </Typography>
            </Container>
          ))}
        </Box>
      </Box>
      <Box>
        <hr
          style={{
            border: "none",
            margin: 0,
            borderTop: "0.2px solid #828282",
          }}
        />
      </Box>
    </Box>
  );
};

export default TypeSelector;
