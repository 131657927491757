import { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { useLoginStore } from './store';

// color design tokens export
export const tokens = (mode) => ({
	...(mode === 'dark'
		? {
				grey: {
					100: '#e0e0e0',
					500: '#666666',
					600: '#2C2C2C',
					900: '#000000',
				},
				primary: {
					100: '#d0d1d5',
					500: '#000000',
					600: '#000000',
					700: '#BBBABA',
					900: '#2C2C2C',
				},
				secondary: {
					100: '#d0d1d5',
					500: '#000000',
					900: '#2C2C2C',
				},
				greenAccent: {
					100: '#dbf5ee',
					500: '#4cceac',
					900: '#0f2922',
				},
				redAccent: {
					100: '#FFE7E7',
					500: '#FF2B2B',
					900: '#2c100f',
				},
				blueAccent: {
					100: '#3F3F42',
					500: '#A29BDC',
					900: '#EBECF5',
				},
		  }
		: {
				grey: {
					100: '#000000',
					500: '#828282',
					600: '#FAFAFA',
					900: '#E5E5E5',
				},
				primary: {
					100: '#141b2d',
					500: '#FFFFFF',
					600: '#F5F5F5',
					700: '#828282',
					900: '#FFFFFF',
				},
				secondary: {
					100: '#141b2d',
					500: '#FAFAFA',
					900: '#FFFFFF',
				},
				greenAccent: {
					100: '#0f2922',
					500: '#4cceac',
					900: '#dbf5ee',
				},
				redAccent: {
					100: '#2c100f',
					500: '#FF2B2B',
					900: '#FFE7E7',
				},
				blueAccent: {
					100: '#EBECF5',
					500: '#5448B2',
					900: '#4639ad',
				},
		  }),
});

// mui theme settings
export const themeSettings = (mode) => {
	const colors = tokens(mode);
	return {
		palette: {
			mode: mode,
			...(mode === 'dark'
				? {
						// palette values for dark mode
						primary: {
							main: colors.primary[500],
						},
						secondary: {
							main: colors.greenAccent[500],
						},
						neutral: {
							dark: colors.grey[900],
							main: colors.grey[500],
							light: colors.grey[100],
						},
						background: {
							default: colors.primary[500],
						},
				  }
				: {
						// palette values for light mode
						primary: {
							main: colors.primary[900],
						},
						secondary: {
							main: colors.blueAccent[500],
						},
						neutral: {
							dark: colors.grey[100],
							main: colors.grey[900],
							light: colors.grey[500],
						},
						background: {
							default: '#Ffffff',
						},
				  }),
		},
		typography: {
			button: {
				textTransform: 'none',
			},
			fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
			fontSize: 12,
			h1: {
				fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
				fontSize: 40,
			},
			h2: {
				fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
				fontSize: 32,
			},
			h3: {
				fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
				fontSize: 24,
			},
			h4: {
				fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
				fontSize: 20,
			},
			h5: {
				fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
				fontSize: 16,
			},
			h6: {
				fontFamily: ['Poppins', 'Source Sans Pro', 'sans-serif'].join(','),
				fontSize: 14,
			},
		},
	};
};

// context for color mode
export const ColorModeContext = createContext({
	toggleColorMode: () => {},
});

export const useMode = () => {
	const [mode, setMode] = useState(useLoginStore.getState().mode || 'light');

	const toggleColorMode = () => {
		const newMode = mode === 'dark' ? 'light' : 'dark';
		setMode(newMode);
		useLoginStore.setState({ mode: newMode });
	};

	useEffect(() => {
		const storedMode = useLoginStore.getState().mode;
		setMode(storedMode || 'light');
	}, []);

	const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

	return [theme, { toggleColorMode }];
};
