import { useEffect, useCallback } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
// import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { useName, useLoginStore } from '../../store/index';
import collapseIcon from '../../assets/collapseIcon.png';
import StyledButton from '../../components/StyledButton';
import { useSelectedStore } from '../../store/index';

const Topbar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	// const colorMode = useContext(ColorModeContext);
	const isCollapsed = useName((state) => state.isCollapsed);
	const setIsCollapsed = useName((state) => state.setIsCollapsed);
	const name = useLoginStore((state) => state.name);
	// const API_KEY = process.env.REACT_APP_API_KEY;
	const token = useLoginStore((state) => state.token);
	const mode = useLoginStore((state) => state.mode);
	// const selected = useSelectedStore((state) => state.selected);
	const setSelected = useSelectedStore((state) => state.setSelected);

	

	const handleSelected = () => {
		setSelected('');
	};

	const handleSideBarCollapse = () => {
		setIsCollapsed();
		console.log('isCollapsed: ' + isCollapsed);
	};

	const handleLogOut = () => {
		// Reset the login store
		useLoginStore.setState({
			isLoggedIn: false,
			name: '',
			token: '',
			username: '',
			mode: 'light',
		});
		localStorage.removeItem('mode');
		localStorage.removeItem('token');
		localStorage.removeItem('name');
	};

	const fetchProfile = useCallback(async () => {
		const API_KEY = process.env.REACT_APP_API_KEY;
		const endPoint = 'Auth/Profile';
		const urlPost = `${API_KEY}/${endPoint}`;
		try {
		  const response = await fetch(urlPost, {
			method: 'GET',
			headers: {
			  'Content-type': 'application/json',
			  Authorization: `Bearer ${token}`,
			},
		  });
	  
		  if (response.ok) {
			const responseData = await response.json();
			const { name } = responseData.data; // Extract token value from data object
			useLoginStore.setState({
			  name: name,
			});
			localStorage.setItem('name', name);
		  } else {
			console.log(response);
		  }
		} catch (error) {
		  console.error(error);
		}
	  }, [token]);
	  
	  useEffect(() => {
		if (localStorage.getItem('name') !== null) {
		  useLoginStore.setState({
			name: localStorage.getItem('name'),
		  });
		} else {
		  fetchProfile();
		}
	  }, [fetchProfile]);
	  
	return (
		<Box
			display='flex'
			justifyContent='space-between'
			paddingTop={2}
			paddingBottom={2}
			backgroundColor={colors.grey[600]}
			className='topBar'
			sx={{
				boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.03)',
				position: 'sticky',
				// top: '0px',
				zIndex: '1005',
			}}>
			<Box position={'relative'} backgroundColor={'transparent'}>
				<Box display={'inline-block'} position={'absolute'} left='-30px' zIndex={'1050'}>
					{isCollapsed ? (
						<img src={collapseIcon} alt='' onClick={() => handleSideBarCollapse()} style={{ transform: 'rotateY(180deg)' }} />
					) : (
						<img src={collapseIcon} alt='' onClick={() => handleSideBarCollapse()} />
					)}
				</Box>
				<Box display={'inline-block'} marginLeft={'98px'}>
					<Typography variant='h6' color={colors.grey[500]}>
						Good morning 👋
					</Typography>
					<Typography variant='h5' color={colors.primary[100]} marginTop={'5px'} sx={{ fontWeight: '600' }}>
						{name}
					</Typography>
				</Box>
			</Box>
			{/* ICONS */}
			<Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
				<IconButton>
					<Link to='/profile' onClick={handleSelected}>
						<AccountCircleIcon sx={{ fontSize: '30px', color: mode === 'light' ? '#5448B2' : '#A29BDC' }} />
					</Link>
				</IconButton>
				<Box sx={{ margin: '10px' }}>
					<StyledButton onClick={handleLogOut} title={'Logout'} />
				</Box>
			</Box>
		</Box>
	);
};

export default Topbar;
