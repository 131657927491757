import { Navigate } from 'react-router-dom';
import { useRef, useState, useEffect, useCallback } from 'react';
import {
	Box,
	useTheme,
	Dialog,
	DialogContentText,
	Button,
	DialogContent,
	DialogTitle,
	DialogActions,
	Typography,
} from '@mui/material';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import SearchBar from '../../components/SearchBar';
import DropDown from '../../components/DropDown';
import StyledButton from '../../components/StyledButton';
import trashIcon from '../../assets/trashIcon.png';
import restoreIcon2 from '../../assets/restoreIcon2.png';
import { useLoginStore } from '../../store';
import ReactPlayer from 'react-player';

function Media() {
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const tableRef = useRef();
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'Media';
	const [page, setPage] = useState(1);
	const pageRef = useRef(1);
	const pageSizeRef = useRef(10);
	const [totalCount, setTotalCount] = useState(0);
	const [mediaData, setMediaData] = useState([]);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState('');
	const urlPost = `${API_KEY}/${endPoint}`;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const token = useLoginStore((state) => state.token);
	const [openMap, setOpenMap] = useState({});
	const [search, setSearch] = useState('');
	const [sortDirection, setSortDirection] = useState('');

	const options = [
		{ label: 'Descending', value: 'desc' },
		{ label: 'Ascending', value: 'asc' },
	];

	const fetchData = useCallback(async () => {
		try {
		  const response = await fetch(
			`${API_KEY}/${endPoint}?sortDirection=${sortDirection}&page=${pageRef.current}&perPage=${pageSizeRef.current}&search=${search}`,
			{
			  method: 'GET',
			  headers: {
				'Content-type': 'application/json',
				Authorization: `Bearer ${token}`,
			  },
			}
		  );
	  
		  if (response.status === 401) {
			// Handle 401 Unauthorized error
			console.log('Unauthorized request');
			// Redirect the user to the login page
			window.location.href = '/login';
			return;
		  }
	  
		  if (!response.ok) {
			// Handle other unsuccessful responses here
			console.log('Request failed:', response.status);
			window.location.href = '/login';
			return;
		  } else {
			const result = await response.json();
			const { media, meta } = result.data;
			console.log(result);
			return {
			  data: media,
			  page: meta.page - 1,
			  totalCount: meta.total,
			};
		  }
		} catch (error) {
		  // Handle any other errors that may occur during the API call
		  console.error('An error occurred:', error);
		  // Perform desired error handling action, such as showing an error message to the user
		}
	  }, [API_KEY, endPoint, sortDirection, pageRef, pageSizeRef, search, token]);

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const result = await fetchData();
		setMediaData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handleSelect = async (sortOption) => {
		// event.preventDefault();
		const result = await fetchData();
		setSortDirection(sortOption);
		setMediaData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	useEffect(() => {
		fetchData();
	  }, [setMediaData, fetchData]);

	const handleOnChange = (event) => {
		setSearch(event.target.value);
	};
	const handlePageChange = (newPage) => {
		pageRef.current = newPage + 1;
		refreshData();
	};

	const handlePageSizeChange = (newPageSize) => {
		pageSizeRef.current = newPageSize;
		refreshData();
	};

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const handleDeleteDialogClose = () => {
		setDeleteDialogOpen(false);
	};

	const openDeleteConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Delete');
		setConfirmationDialogOpen(true);
	};

	const openRestoreConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Restore');
		setConfirmationDialogOpen(true);
	};

	const restoreMedia = (rowData) => {
		setConfirmationDialog('Restore');
		setMediaData({
			id: rowData.id,
			category: rowData.category,
			caption: rowData.caption,
			thumbnailUrl: rowData.thumbnailUrl,
			originalUrl: rowData.originalUrl,
			isDeleted: rowData.isDeleted,
		});
		setDeleteDialogOpen(true);
	};

	const deleteMedia = (rowData) => {
		setConfirmationDialog('Delete');
		setMediaData({
			id: rowData.id,
			category: rowData.category,
			caption: rowData.caption,
			thumbnailUrl: rowData.thumbnailUrl,
			originalUrl: rowData.originalUrl,
			isDeleted: rowData.isDeleted,
		});
		setDeleteDialogOpen(true);
	};

	const handleDeleteSubmit = async (mediaData) =>
		//Logic for deleting a Media
		await fetch(urlPost + '/' + mediaData.id, {
			method: 'DELETE',
			headers: {
				'Content-type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then((resp) => resp.json())
			.then((resp) => {
				openDeleteConfirmationDialog();
				refreshData();
			});

	const handleRestoreSubmit = async (mediaData) =>
		//Logic for restoring a Media
		await fetch(urlPost + '/Restore/' + mediaData.id, {
			method: 'PATCH',
			headers: {
				'Content-type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then((resp) => resp.json())
			.then((resp) => {
				openRestoreConfirmationDialog();
				refreshData();
			});

	const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

	const columns = [
		{ field: 'user.name', title: 'User', flex: 1 },
		{ field: 'category.name', title: 'Category', flex: 1 },
		{ field: 'createdTime', title: 'Created Time', flex: 1 },
		{ field: 'caption', title: 'Caption', flex: 1 },
		{
			field: 'thumbnailUrl',
			title: 'Thumbnail',
			render: (rowData) => {
				const handleClick = () => {
					setOpenMap((prevOpenMap) => ({
						...prevOpenMap,
						[rowData.id]: true,
					}));
				};

				const handleClose = () => {
					setOpenMap((prevOpenMap) => ({
						...prevOpenMap,
						[rowData.id]: false,
					}));
				};

				const isOpen = openMap[rowData.id];

				return (
					<>
						<img
							src={rowData.thumbnailUrl}
							alt='thumbnail'
							style={{ maxWidth: '100px', cursor: 'pointer' }}
							onClick={handleClick}
						/>
						<Dialog open={isOpen} onClose={handleClose}>
							<DialogTitle>{rowData.caption}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									<ReactPlayer
										url={rowData.shortVideoUrl}
										sources={[{ src: rowData.shortVideoUrl, type: 'video/mp4' }]}
										style={{ maxWidth: '30vw' }}
										controls={true}
										// width='50%'
										// height='360px'
									/>
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									style={{
										padding: '8px 8px',
										margin: '8px 8px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</>
				);
			},
		},
	];

	if (!isLoggedIn) {
		return <Navigate to='/' />;
	} else {
		return (
			<Box sx={{ backgroundColor: colors.primary[600] }}>
				<Box m='60px 100px 0px 100px' sx={{ backgroundColor: colors.primary[600] }}>
					<Header title='Manage Media' />
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ flexGrow: 1 }}>
							<SearchBar title='Search Media...' onSubmit={handleFormSubmit} onChange={handleOnChange} value={search} />
						</Box>
						<Box sx={{ marginRight: '15px' }}>
							<DropDown options={options} onSelect={handleSelect} title={'Date'} />
						</Box>
					</Box>
					<Box>
						<MaterialTable
							title='Media Table'
							tableRef={tableRef}
							columns={columns}
							data={fetchData}
							page={page}
							totalCount={totalCount}
							onChangeRowsPerPage={(pageSize) => handlePageSizeChange(pageSize)}
							onChangePage={(page) => handlePageChange(page)}
							actions={[
								(rowData) => ({
									icon: 'restore',
									tooltip: 'Restore',
									onClick: (event, rowData) => {
										restoreMedia(rowData);
									},
									disabled: rowData.isDeleted === false,
								}),
								(rowData) => ({
									icon: 'delete',
									tooltip: 'Delete',
									onClick: (event, rowData) => {
										deleteMedia(rowData);
									},
									disabled: rowData.isDeleted === true,
								}),
							]}
							components={{
								Toolbar: (props) => {
									return (
										<div style={{ backgroundColor: colors.blueAccent[100] }}>
											<MTableToolbar {...props} />
										</div>
									);
								},
							}}
							options={{
								showTitle: false,
								paging: true,
								toolbar: false,
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								maxBodyHeight: '60vh',
								minBodyHeight: '100%',
								columnResizable: true,
								tableLayout: 'auto',
								emptyRowsWhenPaging: false,
								loadingType: 'overlay',
								// pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
								// pageSize: pageSizeRef.current,
								headerStyle: {
									backgroundColor: colors.blueAccent[100],
									paddingRight: '2rem',
									alignItems: 'center',
									color: colors.primary[100],
									fontWeight: 'bold',
								},
								rowStyle: {
									backgroundColor: colors.secondary[900],
								},
							}}
						/>
					</Box>
					{/* The Delete Dialog */}
					<Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
						{confirmationDialog === 'Delete' ? (
							<DialogTitle marginTop={'10px'} align='center' fontWeight={'600'} fontSize={'20px'}>
								Delete media?
							</DialogTitle>
						) : (
							<DialogTitle marginTop={'10px'} align='center' fontWeight={'600'} fontSize={'20px'}>
								Restore media?
							</DialogTitle>
						)}
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Typography marginBottom={'5px'} fontWeight={'600'}>
									{confirmationDialog === 'Delete'
										? 'Are you sure you want to delete'
										: 'Are you sure you want to restore'}
									<Typography display={'inline'} color={colors.blueAccent[500]}>
										{' '}
										{mediaData.name}?{' '}
									</Typography>
								</Typography>
							</Box>
						</DialogContent>
						<DialogActions sx={{ marginBottom: '25px', marginX: '35px' }}>
							<Box>
								{confirmationDialog === 'Delete' ? (
									<StyledButton
										title={`Yes, Delete`}
										onClick={() => handleDeleteSubmit(mediaData)}
										sx={{
											padding: '10px 40px',
											margin: '0px 0px',
											color: colors.redAccent[500],
											backgroundColor: colors.redAccent[900],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								) : (
									<StyledButton
										title={`Yes, restore`}
										onClick={() => handleRestoreSubmit(mediaData)}
										sx={{
											padding: '10px 40px',
											margin: '0px 0px',
											color: colors.blueAccent[500],
											backgroundColor: colors.blueAccent[100],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								)}
							</Box>
							<Box>
								{confirmationDialog === 'Delete' ? (
									<StyledButton
										title={`No, Don't delete`}
										onClick={() => setDeleteDialogOpen(false)}
										sx={{
											padding: '10px 30px',
											margin: '0px 0px',
											color: colors.redAccent[900],
											backgroundColor: colors.redAccent[500],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								) : (
									<StyledButton
										title={`No, Don't restore`}
										onClick={() => setDeleteDialogOpen(false)}
										sx={{
											padding: '10px 30px',
											margin: '0px 0px',
											color: colors.blueAccent[100],
											backgroundColor: colors.blueAccent[500],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								)}
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Confirmation Dialog */}
					<Dialog open={confirmationDialogOpen} onClose={handleConfirmationDialogClose}>
						<DialogTitle marginTop={'8px'} align='center' fontWeight={'600'}>
							{confirmationDialog === 'Delete' ? (
								<img src={trashIcon} alt='delete-icon' width={'80vw'} />
							) : (
								<img src={restoreIcon2} alt='restore-icon' width={'80vw'} />
							)}
						</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Typography marginBottom={'5px'} fontWeight={'600'} fontSize={'20px'}>
									{`${confirmationDialog}d Successfully`}
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
								{`Media is successfully ${confirmationDialog}d.`}
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '25px',
								marginX: '35px',
							}}>
							<Box>
								<StyledButton
									title={`Done`}
									onClick={() => setConfirmationDialogOpen(false)}
									sx={{
										padding: '1vh 8vw',
										margin: '0px 0px',
										color: colors.primary[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
				</Box>
			</Box>
		);
	}
}
export default Media;
