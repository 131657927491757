import { useRef, useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import {
	Box,
	useTheme,
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	DialogActions,
	FormControl,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import SearchBar from '../../components/SearchBar';
import StyledButton from '../../components/StyledButton';
import DropDown from '../../components/DropDown';
import addIcon2 from '../../assets/addIcon2.png';
import { useLoginStore } from '../../store';

function AppVersions() {
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const tableRef = useRef();
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'app-versions';
	const [page, setPage] = useState(1);
	const pageRef = useRef(1);
	const pageSizeRef = useRef(10);
	const [totalCount, setTotalCount] = useState(0);
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState('');
	const [versionData, setVersionData] = useState({});
	const urlPost = `${API_KEY}/${endPoint}`;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const token = useLoginStore((state) => state.token);
	const [sortDirection, setSortDirection] = useState('');

	const sortingOptions = [
		{ label: 'Descending', value: 'desc' },
		{ label: 'Ascending', value: 'asc' },
	];

	const fetchData = useCallback(async () => {
		try {
		  const response = await fetch(
			`${API_KEY}/${endPoint}?sortDirection=${sortDirection}&page=${pageRef.current}&perPage=${pageSizeRef.current}&search=${search}`,
			{
			  method: 'GET',
			  headers: {
				'Content-type': 'application/json',
				Authorization: `Bearer ${token}`,
			  },
			}
		  );
	  
		  if (response.status === 401) {
			// Handle 401 Unauthorized error
			console.log('Unauthorized request');
			// Redirect the user to the login page
			window.location.href = '/login';
			return;
		  }
	  
		  if (!response.ok) {
			// Handle other unsuccessful responses here
			console.log('Request failed:', response.status);
			window.location.href = '/login';
			return;
		  } else {
			const result = await response.json();
			const { versions, meta } = result.data;
			console.log(result);
			return {
			  data: versions,
			  page: meta.page - 1,
			  totalCount: meta.total,
			};
		  }
		} catch (error) {
		  // Handle any other errors that may occur during the API call
		  console.error('An error occurred:', error);
		  // Perform desired error handling action, such as showing an error message to the user
		}
	  }, [API_KEY, endPoint, sortDirection, pageRef, pageSizeRef, search, token]);
	  
	  useEffect(() => {
		fetchData();
	  }, [fetchData, setVersionData, setSortDirection]);
	  


	const handleOnChange = (event) => {
		setSearch(event.target.value);
	};

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		const result = await fetchData();
		setVersionData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};

	const handleSelect = async (sortOption) => {
		// event.preventDefault();
		const result = await fetchData();
		setSortDirection(sortOption);
		setVersionData(result.data);
		setPage(result.page);
		setTotalCount(result.totalCount);
		refreshData();
	};


	const handlePageChange = (newPage) => {
		pageRef.current = newPage + 1;
		refreshData();
	};

	const handlePageSizeChange = (newPageSize) => {
		pageSizeRef.current = newPageSize;
		refreshData();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddDialogOpen = () => {
		setVersionData({});
		setAddDialogOpen(true);
	};

	const handleAddDialogClose = () => {
		setAddDialogOpen(false);
	};

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const addVersion = () => {
		setConfirmationDialog('Add');
		setVersionData({
			version: versionData.version,
			status: versionData.status,
		});
		handleAddSubmit();
		setConfirmationDialogOpen(true);
	};

	const editVersion = (rowData) => {
		setVersionData({
			id: rowData.id,
			version: rowData.version,
			status: rowData.status,
		});
		setOpen(true);
	};

	const handleAddSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for adding a version on the Database
			fetch(urlPost, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					// id: versionData.id,
					version: versionData.version,
					status: versionData.status,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleAddDialogClose();
					refreshData();
					// console.log(resp.message)
				});
		});

	const handleEditSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for updating a version on the Database
			fetch(urlPost + '/' + versionData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: versionData.id,
					version: versionData.version,
					status: versionData.status,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleClose();
					refreshData();
				});
		});

	const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

	const columns = [
		{ field: 'version', title: 'Version', flex: 1 },
		{
			field: 'status',
			title: 'Status',
			flex: 1,
			lookup: {
				NoUpdates: 'No Updates',
				RecommendedUpdate: 'Recommended Update',
				ForceUpdate: 'ForceUpdate',
			},
		},
	];

	if (!isLoggedIn) {
		return <Navigate to='/' />;
	} else {
		return (
			<Box pt={'10px'} sx={{ backgroundColor: colors.primary[600] }}>
				<Box m='60px 100px 0px 100px' sx={{ backgroundColor: colors.primary[600] }}>
					<Header title='Manage App Versions' />
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ flexGrow: 1, marginRight: '15px' }}>
							<SearchBar
								title='Search Versions...'
								onSubmit={handleFormSubmit}
								onChange={handleOnChange}
								value={search}
							/>
						</Box>
						<StyledButton title={'Add Version'} onClick={() => handleAddDialogOpen(true)} />
						<Box sx={{ marginRight: '15px' }}>
							<DropDown options={sortingOptions} onSelect={handleSelect} title={'Version'} />
						</Box>
					</Box>

					<Box>
						<MaterialTable
							title='App Versions Table'
							tableRef={tableRef}
							columns={columns}
							data={fetchData}
							page={page}
							totalCount={totalCount}
							onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
							onChangePage={(params) => handlePageChange(params)}
							actions={[
								(rowData) => ({
									icon: 'edit',
									tooltip: 'Edit',
									onClick: (event, rowData) => editVersion(rowData),
								}),
							]}
							components={{
								Toolbar: (props) => {
									return (
										<div style={{ backgroundColor: colors.primary[400] }}>
											<MTableToolbar {...props} />
										</div>
									);
								},
							}}
							options={{
								showTitle: false,
								paging: true,
								toolbar: false,
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								maxBodyHeight: '71vh',
								minBodyHeight: '100%',
								columnResizable: true,
								tableLayout: 'auto',
								emptyRowsWhenPaging: false,
								loadingType: 'overlay',
								// pageSizeOptions: [5, 10, 15, 20, 25, 30, 50, 100],
								// pageSize: pageSizeRef.current,
								headerStyle: {
									backgroundColor: colors.blueAccent[100],
									paddingRight: '2rem',
									alignItems: 'center',
									color: colors.primary[100],
									fontWeight: 'bold',
								},
								rowStyle: {
									backgroundColor: colors.secondary[900],
								},
							}}
						/>
					</Box>
					{/* The Add Dialog */}
					<Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Add version
							</DialogTitle>
						</Box>
						<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Version
									</Typography>
									<TextField
										sx={{ backgroundColor: colors.blueAccent[100], width: '12vw' }}
										size={'small'}
										value={versionData.version}
										onChange={(e) => setVersionData({ ...versionData, version: e.target.value })}
									/>
								</Box>
								<FormControl
									sx={{
										// marginRight: '25px',
										marginLeft: '25px',
										width: '12vw',
									}}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Status
									</Typography>
									<Select
										size={'small'}
										sx={{ backgroundColor: colors.blueAccent[100] }}
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={versionData.status}
										onChange={(e) => setVersionData({ ...versionData, status: e.target.value })}>
										<MenuItem value={`NoUpdates`}>No Updates</MenuItem>
										<MenuItem value={`RecommendedUpdate`}>Recommended Update</MenuItem>
										<MenuItem value={`ForceUpdate`}>Force Update</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}>
							{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
							<Box>
								<StyledButton
									title={`Save`}
									onClick={() => addVersion()}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Editing Dialog */}
					<Dialog open={open} onClose={handleClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Edit version
							</DialogTitle>
						</Box>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Version
									</Typography>
									<TextField
										sx={{ backgroundColor: colors.blueAccent[100], width: '15vw' }}
										size={'small'}
										value={versionData.version}
										onChange={(e) => setVersionData({ ...versionData, version: e.target.value })}
									/>
								</Box>
								<FormControl
									sx={{
										// marginRight: '25px',
										marginLeft: '25px',
										width: '12vw',
									}}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Status
									</Typography>
									<Select
										size={'small'}
										sx={{ backgroundColor: colors.blueAccent[100] }}
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										value={versionData.status}
										onChange={(e) => setVersionData({ ...versionData, status: e.target.value })}>
										<MenuItem value={`NoUpdates`}>No Updates</MenuItem>
										<MenuItem value={`RecommendedUpdate`}>Recommended Update</MenuItem>
										<MenuItem value={`ForceUpdate`}>Force Update</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}>
							{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
							<Box>
								<StyledButton
									title={`Save`}
									onClick={() => handleEditSubmit(true)}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Confirmation Dialog */}
					<Dialog open={confirmationDialogOpen} onClose={handleConfirmationDialogClose}>
						<DialogTitle marginTop={'8px'} align='center' fontWeight={'600'}>
							{confirmationDialog === 'Add' ? (
								<img src={addIcon2} alt='' width={'80vw'} />
							) : (
								<img src={addIcon2} alt='' width={'80vw'} />
							)}
						</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}>
								<Typography marginBottom={'5px'} fontWeight={'600'} fontSize={'20px'}>
									{confirmationDialog === 'Add'
										? `${confirmationDialog}ed Successfully`
										: `${confirmationDialog}ed Successfully`}
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
								{confirmationDialog === 'Add'
									? `Version "${versionData.version}" is successfully ${confirmationDialog}ed`
									: `Version "${versionData.version}" is successfully ${confirmationDialog}ed.`}
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '25px',
								marginX: '35px',
							}}>
							<Box>
								<StyledButton
									title={`Done`}
									onClick={() => setConfirmationDialogOpen(false)}
									sx={{
										padding: '1vh 8vw',
										margin: '0px 0px',
										color: colors.primary[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
				</Box>
			</Box>
		);
	}
}
export default AppVersions;
