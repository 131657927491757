import { useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
	Box,
	useTheme,
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	DialogActions,
	FormControl,
	Typography,
} from '@mui/material';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from '../../theme';
import SearchBar from '../../components/SearchBar';
import StyledButton from '../../components/StyledButton';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import trashIcon from '../../assets/trashIcon.png';
import restoreIcon2 from '../../assets/restoreIcon2.png';
import addIcon2 from '../../assets/addIcon2.png';
import { useLoginStore } from '../../store/index.js';

function Events() {
	const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
	const tableRef = useRef();
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'Events';
	const [open, setOpen] = useState(false);
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [confirmationDialog, setConfirmationDialog] = useState('');
	const [eventData, setEventData] = useState({});
	// const [page, setPage] = useState(1);
	// const [pageSize, setPageSize] = useState(10);
	const pageRef = useRef(1);
	const pageSizeRef = useRef(10);
	const urlPost = `${API_KEY}/${endPoint}`;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const token = useLoginStore((state) => state.token);

	const handlePageChange = (newPage) => {
		pageRef.current = newPage + 1;
		refreshData();
	};

	const handlePageSizeChange = (newPageSize) => {
		pageSizeRef.current = newPageSize;
		refreshData();
	};
	const handleClose = () => {
		setOpen(false);
	};

	// const handleGifInputChange = (e) => {
	// 	const file = e.target.files[0];
	// 	setEventData({ ...eventData, gif: file });
	// };

	// const handleGifFieldClick = () => {
	// 	document.getElementById('gifInput').click();
	// };

	const handleImageInputChange = (e) => {
		const file = e.target.files[0];
		setEventData({ ...eventData, image: file });
	};

	const handleImageFieldClick = () => {
		document.getElementById('imageInput').click();
	};

	const handleStartDateChange = (date) => {
		setEventData({ ...eventData, startDate: date });
	};

	const handleEndDateChange = (date) => {
		setEventData({ ...eventData, endDate: date });
	};

	const handleAddDialogOpen = () => {
		setEventData({});
		setAddDialogOpen(true);
	};

	const handleAddDialogClose = () => {
		setAddDialogOpen(false);
	};

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const handleDeleteDialogClose = () => {
		setDeleteDialogOpen(false);
	};

	const openDeleteConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Delete');
		setConfirmationDialogOpen(true);
	};

	const openRestoreConfirmationDialog = () => {
		setDeleteDialogOpen(false);
		setConfirmationDialog('Restore');
		setConfirmationDialogOpen(true);
	};

	const refreshData = () => tableRef.current && tableRef.current.onQueryChange();

	const restoreEvent = (rowData) => {
		setConfirmationDialog('Restore');
		setEventData({
			id: rowData.id,
			name: rowData.name,
			image: rowData.image,
			hastag: rowData.hashtag,
			startDate: rowData.startDate,
			endDate: rowData.endDate,
			recurring: rowData.recurring,
		});
		setDeleteDialogOpen(true);
	};

	const deleteEvent = (rowData) => {
		setConfirmationDialog('Delete');
		setEventData({
			id: rowData.id,
			name: rowData.name,
			image: rowData.image,
			hastag: rowData.hashtag,
			startDate: rowData.startDate,
			endDate: rowData.endDate,
			recurring: rowData.recurring,
		});
		setDeleteDialogOpen(true);
	};

	const addEvent = () => {
		setConfirmationDialog('Add');
		setEventData({
			id: eventData.id,
			name: eventData.name,
			image: eventData.image,
			hastag: eventData.hashtag,
			startDate: eventData.startDate,
			endDate: eventData.endDate,
			recurring: eventData.recurring,
		});
		handleAddSubmit();
		setConfirmationDialogOpen(true);
	};

	const editEvent = (rowData) => {
		setEventData({
			id: rowData.id,
			name: rowData.name,
			image: rowData.image,
			hastag: rowData.hashtag,
			startDate: rowData.startDate,
			endDate: rowData.endDate,
			recurring: rowData.recurring,
		});
		setOpen(true);
	};

	const handleEditSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for updating an Event on the Database
			fetch(urlPost + '/' + eventData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: eventData.id,
					name: eventData.name,
					image: eventData.image,
					hastag: eventData.hashtag,
					startDate: eventData.startDate,
					endDate: eventData.endDate,
					recurring: eventData.recurring,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleClose();
					refreshData();
				});
		});

	const handleAddSubmit = () =>
		new Promise((resolve, reject) => {
			//Logic for adding an Event on the Database
			fetch(urlPost, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					// id: eventData.id,
					name: eventData.name,
					image: eventData.image,
					hastag: eventData.hashtag,
					startDate: eventData.startDate,
					endDate: eventData.endDate,
					recurring: eventData.recurring,
				}),
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					handleAddDialogClose();
					refreshData();
					// console.log(resp.message)
				});
		});

	const handelDeleteSubmit = (eventData) =>
		new Promise((resolve, reject) => {
			//Logic for deleting a Event
			fetch(urlPost + '/' + eventData.id, {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					openDeleteConfirmationDialog();
					refreshData();
				});
		});

	const handleRestoreSubmit = (rowData) =>
		new Promise((resolve, reject) => {
			//Logic for restoring a Event
			fetch(urlPost + '/Restore/' + rowData.id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			})
				.then((resp) => resp.json())
				.then((resp) => {
					resolve();
					openRestoreConfirmationDialog();
					refreshData();
				});
		});

	const columns = [
		{ title: 'Name Of Event', field: 'name' },
		{ title: 'Image', field: 'image' },
		{ title: 'Hashtag', field: 'hashtag' },
		{ title: 'Start Date', field: 'startDate' },
		{ title: 'End Date', field: 'endDate' },
		{ title: 'Recurring', field: 'recurring' },
	];
	if (!isLoggedIn) {
		return <Navigate to='/' replace />;
	} else {
		return (
			<Box pt={'10px'} sx={{ backgroundColor: colors.primary[600] }}>
				<Box m='10px 20px 10px 20px' sx={{ backgroundColor: colors.primary[600] }}>
					<Header title='Manage Events' />
					<Box mb='10px' sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<SearchBar title={`Search Events...`} />
						<Box>
							<StyledButton
								title={`Add Event`}
								startIcon={<AddBoxOutlinedIcon />}
								onClick={() => handleAddDialogOpen()}
							/>
						</Box>
					</Box>
					<Box>
						<MaterialTable
							title='Events Table'
							tableRef={tableRef}
							columns={columns}
							data={(query) =>
								new Promise((resolve, reject) => {
									fetch(
										`${API_KEY}/${endPoint}?&page=${pageRef.current}&perPage=${pageSizeRef.current}`,
										{
											headers: {
												'Content-type': 'application/json',
												Authorization: `Bearer ${token}`,
											},
										}
									)
										.then((resp) => resp.json())
										.then((resp) =>
											resolve({
												data: resp.data.events, // your data array
												page: resp.data.meta.page - 1, // current page number
												totalCount: resp.data.meta.total, // total row number
											})
										);
								})
							}
							onChangeRowsPerPage={(params) => handlePageSizeChange(params)}
							onChangePage={(params) => handlePageChange(params)}
							actions={[
								(rowData) => ({
									icon: 'restore',
									tooltip: 'Restore',
									onClick: (event, rowData) => {
										restoreEvent(rowData);
									},
									disabled: rowData.isDeleted === false,
								}),
								(rowData) => ({
									icon: 'delete',
									tooltip: 'Delete',
									onClick: (event, rowData) => {
										deleteEvent(rowData);
									},
									disabled: rowData.isDeleted === true,
								}),
								{
									icon: `edit`,
									tooltip: 'Edit',
									onClick: (event, rowData) => editEvent(rowData),
								},
							]}
							components={{
								Toolbar: (props) => {
									return (
										<div style={{ backgroundColor: colors.blueAccent[100] }}>
											<MTableToolbar {...props} />
										</div>
									);
								},
							}}
							options={{
								showTitle: false,
								toolbar: false,
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								maxBodyHeight: '80vh',
								minBodyHeight: '80vh',
								columnResizable: true,
								tableLayout: 'auto',
								emptyRowsWhenPaging: true,
								loadingType: 'overlay',
								headerStyle: {
									backgroundColor: colors.blueAccent[100],
									paddingRight: '2rem',
									alignItems: 'center',
									color: colors.primary[100],
									fontWeight: 'bold',
								},
								rowStyle: {
									backgroundColor: colors.secondary[900],
								},
							}}
						/>
					</Box>
					{/* The Editing Dialog */}
					<Dialog open={open} onClose={handleClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Edit Event
							</DialogTitle>
						</Box>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginBottom: '30px',
									marginLeft: '25px',
									marginRight: '25px',
								}}
							>
								<Box sx={{ marginRight: '35px' }}>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Name
									</Typography>
									<TextField
										sx={{ backgroundColor: colors.blueAccent[100], width: '15vw' }}
										size={'small'}
										value={eventData.name}
										onChange={(e) =>
											setEventData({ ...eventData, name: e.target.value })
										}
									/>
								</Box>
								<Box>
									<Typography marginBottom={'5px'} fontWeight={'600'}>
										Price
									</Typography>
									<TextField
										sx={{ backgroundColor: colors.blueAccent[100], width: '15vw' }}
										size={'small'}
										value={eventData.price}
										onChange={(e) =>
											setEventData({ ...eventData, price: e.target.value })
										}
									/>
								</Box>
							</Box>
							<FormControl
								sx={{
									marginRight: '25px',
									marginLeft: '25px',
									width: '15vw',
								}}
							></FormControl>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}
						>
							{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
							<Box>
								<StyledButton
									title={`Save`}
									onClick={() => handleEditSubmit(true)}
									sx={{
										padding: '10px 40px',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Add Dialog */}
					<Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<DialogTitle fontWeight={'600'} fontSize={'20px'}>
								Add Event
							</DialogTitle>
						</Box>
						<DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
							<Box>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}
								>
									<Box
										sx={{
											flex: 1,
										}}
									>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Name Of Event
										</Typography>
										<input
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '100%',
												padding: '10px 0px 10px 14px',
												border: '1px solid #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Name'
											size={'small'}
											value={eventData.name}
											onChange={(e) =>
												setEventData({ ...eventData, name: e.target.value })
											}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}
								>
									<Box sx={{ marginRight: '35px' }}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Image
										</Typography>
										<input
											className='imageUploader-gift'
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '15vw',
												padding: '10px 0px 10px 14px',
												border: '1px dashed #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Image PNG or JPEG'
											size={'small'}
											value={eventData.image ? eventData.image.name : ''}
											onClick={handleImageFieldClick}
											onChange={(e) =>
												setEventData({ ...eventData, image: e.target.value })
											}
										/>
										{/* Handling the upload of the Image */}
										<input
											id='imageInput'
											accept='image/png, image/jpeg'
											type='file'
											style={{ display: 'none' }}
											onChange={handleImageInputChange}
										/>
									</Box>
									<Box>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Hashtag
										</Typography>
										<input
											// className='imageUploader-gift'
											style={{
												backgroundColor: colors.blueAccent[100],
												width: '15vw',
												padding: '10px 0px 10px 14px',
												border: '1px dashed #C7C7C7',
												borderRadius: '8px',
											}}
											placeholder='Hashtag'
											size={'small'}
											value={eventData.hashtag}
											onChange={(e) =>
												setEventData({ ...eventData, hashtag: e.target.value })
											}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										marginBottom: '30px',
										marginLeft: '25px',
										marginRight: '25px',
									}}
								>
									<Box sx={{ marginRight: '35px' }}>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											Start Date
										</Typography>
										<DatePicker
											className='dateInputPicker-gift'
											selected={eventData.startDate}
											onChange={handleStartDateChange}
											placeholderText='Start Date'
											dateFormat='yyyy-MM-dd'
											size='small'
											minDate={new Date()}
										/>
									</Box>
									<Box>
										<Typography marginBottom={'5px'} fontWeight={'600'}>
											End Date
										</Typography>
										<DatePicker
											className='dateInputPicker-gift'
											selected={eventData.endDate}
											onChange={handleEndDateChange}
											placeholderText='End Date'
											dateFormat='yyyy-MM-dd'
											size='small'
										/>
									</Box>
								</Box>
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								marginBottom: '25px',
								marginRight: '35px',
							}}
						>
							{/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
							<Box>
								<StyledButton
									title={`Add Event`}
									onClick={() => addEvent()}
									sx={{
										padding: '20px 24x',
										margin: '0px 0px',
										color: colors.grey[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Delete Dialog */}
					<Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
						{confirmationDialog === 'Delete' ? (
							<DialogTitle
								marginTop={'10px'}
								align='center'
								fontWeight={'600'}
								fontSize={'20px'}
							>
								Delete Event?
							</DialogTitle>
						) : (
							<DialogTitle
								marginTop={'10px'}
								align='center'
								fontWeight={'600'}
								fontSize={'20px'}
							>
								Restore Event?
							</DialogTitle>
						)}
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}
							>
								<Typography marginBottom={'5px'} fontWeight={'600'}>
									{confirmationDialog === 'Delete'
										? 'Are you sure you want to delete'
										: 'Are you sure you want to restore'}
									<Typography display={'inline'} color={colors.blueAccent[500]}>
										{' '}
										{eventData.name}?{' '}
									</Typography>
								</Typography>
							</Box>
						</DialogContent>
						<DialogActions sx={{ marginBottom: '25px', marginX: '35px' }}>
							<Box>
								{confirmationDialog === 'Delete' ? (
									<StyledButton
										title={`Yes, Delete`}
										onClick={() => handelDeleteSubmit(eventData)}
										sx={{
											padding: '10px 40px',
											margin: '0px 0px',
											color: colors.redAccent[500],
											backgroundColor: colors.redAccent[900],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								) : (
									<StyledButton
										title={`Yes, restore`}
										onClick={() => handleRestoreSubmit(eventData)}
										sx={{
											padding: '10px 40px',
											margin: '0px 0px',
											color: colors.blueAccent[500],
											backgroundColor: colors.blueAccent[100],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								)}
							</Box>
							<Box>
								{confirmationDialog === 'Delete' ? (
									<StyledButton
										title={`No, Don't delete`}
										onClick={() => setDeleteDialogOpen(false)}
										sx={{
											padding: '10px 30px',
											margin: '0px 0px',
											color: colors.redAccent[900],
											backgroundColor: colors.redAccent[500],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								) : (
									<StyledButton
										title={`No, Don't restore`}
										onClick={() => setDeleteDialogOpen(false)}
										sx={{
											padding: '10px 30px',
											margin: '0px 0px',
											color: colors.blueAccent[100],
											backgroundColor: colors.blueAccent[500],
											'&:hover': {
												color: colors.grey[900],
												backgroundColor: colors.blueAccent[900],
											},
										}}
									/>
								)}
							</Box>
						</DialogActions>
					</Dialog>
					{/* The Confirmation Dialog */}
					<Dialog
						open={confirmationDialogOpen}
						onClose={handleConfirmationDialogClose}
					>
						<DialogTitle marginTop={'8px'} align='center' fontWeight={'600'}>
							{confirmationDialog === 'Delete' ? (
								<img src={trashIcon} alt='' width={'80vw'} />
							) : confirmationDialog === 'Add' ? (
								<img src={addIcon2} alt='' width={'80vw'} />
							) : (
								<img src={restoreIcon2} alt='' width={'80vw'} />
							)}
						</DialogTitle>
						<DialogContent>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									marginY: '0px',
									marginLeft: '25px',
									marginRight: '25px',
								}}
							>
								<Typography
									marginBottom={'5px'}
									fontWeight={'600'}
									fontSize={'20px'}
								>
									{confirmationDialog === 'Add'
										? `${confirmationDialog}ed Successfully`
										: `${confirmationDialog}d Successfully`}
								</Typography>
							</Box>
							<Box
								sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
							>
								{confirmationDialog === 'Add'
									? `${eventData.name} is successfully ${confirmationDialog}ed`
									: `${eventData.name} is successfully ${confirmationDialog}d.`}
							</Box>
						</DialogContent>
						<DialogActions
							sx={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '25px',
								marginX: '35px',
							}}
						>
							<Box>
								<StyledButton
									title={`Done`}
									onClick={() => setConfirmationDialogOpen(false)}
									sx={{
										padding: '1vh 8vw',
										margin: '0px 0px',
										color: colors.primary[900],
										backgroundColor: colors.blueAccent[500],
										'&:hover': {
											color: colors.grey[900],
											backgroundColor: colors.blueAccent[900],
										},
									}}
								/>
							</Box>
						</DialogActions>
					</Dialog>
				</Box>
			</Box>
		);
	}
}
export default Events;
