import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
// import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import StyledButton from "../../components/StyledButton";
import { tokens } from "../../theme";
// import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import moment from "moment";
import addIcon2 from "../../assets/addIcon2.png";
import restoreIcon2 from "../../assets/restoreIcon2.png";
import trashIcon from "../../assets/trashIcon.png";
import backButton from "../../assets/backButton.svg";
import paymentCheck from "../../assets/payment-check.svg";
import paymentCross from "../../assets/payment-cross.svg";
import { PAYMENT_TABS } from "../../database/payments.js";
import { useLoginStore } from "../../store/index.js";

function Payments() {
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const token = useLoginStore((state) => state.token);
  const tableRef = useRef();
  const API_KEY = process.env.REACT_APP_API_KEY;
  const endPoint = "Payments/Withdraws";
  const endPointAction = "Payments/Withdraw";
  const endPointParam = "Payments/Withdraws";
  const [open, setOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState("");
  const [stickerData, setStickerData] = useState({});
  const [type] = useState("Sticker");
  const [page] = useState(1);
  const pageRef = useRef(1);
  const pageSizeRef = useRef(10);
  const [totalCount] = useState(0);
  const [search, setSearch] = useState("");
  const urlPost = `${API_KEY}/${endPoint}`;
  const urlPostAction = `${API_KEY}/${endPointAction}`;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [status, setStatus] = useState("");
  const [withdrawId, setWithdrawId] = useState(0);
  const [transactionId, setTransactionId] = useState("");
  const [name, setName] = useState("");
  const [noOfDiamonds, setNoOfDiamonds] = useState("");
  const [accountName, setAccountName] = useState("");
  const [username, setUsername] = useState("");
  const [amountInDollars, setAmountInDollars] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [date, setDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [IBAN, setIBAN] = useState("");
  const [rowData, setRowData] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentRequestColor, setPaymentRequestColor] = useState("text-active");
  const [paymentRequestBg, setPaymentRequestBg] = useState("bg-white");
  const [paymentRequestBorder, setPaymentRequestBorder] =
    useState("border-white");
  const [paymentRequestChild, setPaymentRequestChild] = useState(
    <div className="size-3 rounded-full bg-active" />
  );

  const [processingRequestColor, setProcessingRequestColor] =
    useState("text-grey");
  const [processingRequestBg, setProcessingRequestBg] = useState("bg-white");
  const [processingRequestBorder, setProcessingRequestBorder] =
    useState("border-hollow");
  const [processingRequestChild, setProcessingRequestChild] = useState(
    <div className="size-3 rounded-full bg-white" />
  );

  const [completedRequestColor, setCompletedRequestColor] =
    useState("text-grey");
  const [completedRequestBg, setCompletedRequestBg] = useState("bg-white");
  const [completedRequestBorder, setCompletedRequestBorder] =
    useState("border-hollow");
  const [completedRequestChild, setCompletedRequestChild] = useState(
    <div className="size-3 rounded-full bg-white" />
  );

  const [firstLineHeight, setFirstLineHeight] = useState("h-[1px]");
  const [firstLineBg, setFirstLineBg] = useState("bg-tabColor");
  const [secondLineHeight, setSecondLineHeight] = useState("h-[1px]");
  const [secondLineBg, setSecondLineBg] = useState("bg-tabColor");

  // const options = [
  //   "All",
  //   "Pending requests",
  //   "Processing",
  //   "Completed",
  //   "Declined from admin",
  //   "Cancelled from user",
  // ];
  const [currentIndex, setCurrentIndex] = useState(0);
  // const navigate = useNavigate();
  const [isDetailOpened, setIsDetailOpened] = useState(false);

  // const fetchData = useCallback(async () => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [API_KEY, endPoint, token]);

  const fetchData = async () => {
    // Create a URL object
    let url = new URL(`${API_KEY}/${endPointParam}`);

    // Create a new URLSearchParams object from the URL search parameters
    let searchParams = new URLSearchParams(url.search);

    // Add a query parameter
    if (status) searchParams.append("status", status);

    searchParams.append("pageNumber", pageRef.current);
    searchParams.append("pageSize", pageSizeRef.current);

    // Update the search property of the URL object with the new search parameters
    url.search = searchParams;
    console.log(url.href);

    try {
      const response = await fetch(url.href, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized request");
        // Redirect the user to the login page
        // window.location.href = "/login";
        return;
      }

      if (!response.ok) {
        // Handle other unsuccessful responses here
        console.log("Request failed:", response.status);
        // window.location.href = "/login";
        return;
      } else {
        const result = await response.json();
        const { withdraws, meta } = result.data;
        console.log("Payments : ", withdraws);
        return {
          data: withdraws,
          page: meta?.page - 1,
          totalCount: meta?.total,
        };
      }
    } catch (error) {
      // Handle any other errors that may occur during the API call
      console.error("An error occurred:", error);
      // Perform desired error handling action, such as showing an error message to the user
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // useEffect(() => {
  //   console.log("Status : ", status);
  // }, [status]);

  const handleOnChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const result = await fetchData();
    setStickerData(result);
    // setPage(result.page);
    // setTotalCount(result.totalCount);
    refreshData();
  };

  const handleTypeSelect = (selectedOption, index) => {
    setStatus(selectedOption);
    setCurrentIndex(index);
    // setType(selectedOption);
    refreshData();
  };

  const handlePageChange = (newPage) => {
    pageRef.current = newPage + 1;
    refreshData();
  };

  const handlePageSizeChange = (newPageSize) => {
    pageSizeRef.current = newPageSize;
    refreshData();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // const openDeleteConfirmationDialog = () => {
  //   setDeleteDialogOpen(false);
  //   setConfirmationDialog("Approve");
  //   setConfirmationDialogOpen(true);
  // };

  const openRestoreConfirmationDialog = (reqType) => {
    setDeleteDialogOpen(false);
    setConfirmationDialog(reqType);
    setConfirmationDialogOpen(true);
  };

  const refreshData = () =>
    tableRef.current && tableRef.current.onQueryChange();

  const declinePayment = (rowData) => {
    setConfirmationDialog("Decline");
    setWithdrawId(rowData?._id);
    setStickerData({
      id: rowData.id,
      type: rowData.type,
      name: rowData.name,
      url: rowData.url,
    });
    setDeleteDialogOpen(true);
  };

  const approvePayment = (rowData) => {
    console.log("approve");
    setConfirmationDialog("Approve");
    setWithdrawId(rowData?._id);
    setStickerData({
      id: rowData.id,
      type: rowData.type,
      name: rowData.name,
      url: rowData.url,
    });
    setDeleteDialogOpen(true);
  };

  const addSticker = () => {
    setConfirmationDialog("Add");
    setStickerData({
      name: stickerData.name,
      url: stickerData.url,
      file: stickerData.file,
    });
    handleAddSubmit();
    setConfirmationDialogOpen(true);
  };

  // const declinePayment = (rowData) => {
  //   setStickerData({
  //     id: rowData.id,
  //     type: rowData.type,
  //     name: rowData.name,
  //     url: rowData.url,
  //   });
  //   setOpen(true);
  // };

  const handleEditSubmit = () =>
    new Promise((resolve, reject) => {
      //Logic for updating a Sticker on the Database
      fetch(urlPost + "/" + stickerData.id, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: stickerData.id,
          type: stickerData.type,
          name: stickerData.name,
          url: stickerData.url,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve();
          handleClose();
          refreshData();
        });
    });

  const handleAddSubmit = () =>
    new Promise((resolve, reject) => {
      //Logic for adding a Sticker on the Database
      fetch(urlPost, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          // id: stickerData.id,
          type: type,
          name: stickerData.name,
          url: stickerData.url,
          // file: stickerData.file
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve();
          handleAddDialogClose();
          refreshData();
          // console.log(resp.message)
        });
    });

  // const handleDisapprove = (stickerData) =>
  //   new Promise((resolve, reject) => {
  //     //Logic for deleting a Sticker
  //     fetch(urlPost + "/" + stickerData.id, {
  //       method: "DELETE",
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((resp) => resp.json())
  //       .then((resp) => {
  //         resolve();
  //         openDeleteConfirmationDialog();
  //         refreshData();
  //       });
  //   });

  const handleRequest = (rowData, reqType) => {
    new Promise((resolve, reject) => {
      //Logic for restoring a Sticker
      fetch(urlPostAction + `/${reqType}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          // id: stickerData.id,
          withdrawId: rowData,
          // file: stickerData.file
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve();
          openRestoreConfirmationDialog(reqType);
          refreshData();
          if(isDetailOpened){
            setIsDetailOpened(false);
          }
        });
    });
  };

  const columns = [
    {
      title: "Transaction ID",
      field: "transactionId",
      // lookup: { Sticker: "Sticker", Gif: "Gif", Emoji: "Emoji" },
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Username",
      field: "username",
      // lookup: { true: "true", false: "false" },
      // editable: "never",
    },
    {
      title: "No. of diamonds",
      field: "diamonds",
      // editable: "never",
    },
    {
      title: "Amount in $",
      field: "amountInDollars",
    },
    {
      title: "Date",
      field: "createdTime",
      render: (rowData) => (
        <span>{moment(rowData.createdTime).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "Payment Method",
      field: "paymentMethod",
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <span
          style={{
            backgroundColor: setBackgroundColor(rowData),
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 7,
            paddingBottom: 7,
            gap: 8,
            borderRadius: 4,
            color: setColor(rowData),
            fontWeight: 500,
          }}
        >
          {rowData.status}
        </span>
      ),
      hidden: status !== "",
    },
  ];

  const setBackgroundColor = (rowData) => {
    if (rowData?.status === "Pending") {
      return "#F4EADB";
    } else if (rowData?.status === "Processing") {
      return "#E1EDF5";
    } else if (
      rowData?.status === "Approved" ||
      rowData?.status === "Completed"
    ) {
      return "#E7F4F3";
    } else if (rowData?.status === "Declined") {
      return "#F0E6E6";
    } else {
      return "#FFE7E7";
    }
  };

  const setColor = (rowData) => {
    if (rowData?.status === "Pending") {
      return "#DAA553";
    } else if (rowData?.status === "Processing") {
      return "#468DBF";
    } else if (
      rowData?.status === "Approved" ||
      rowData?.status === "Completed"
    ) {
      return "#0E9384";
    } else if (rowData?.status === "Declined") {
      return "#680000";
    } else {
      return "#FF2A2A";
    }
  };

  const handleRowClick = (event, rowData) => {
    // Assuming rowData contains an ID or other unique identifier for the row
    // const rowId = rowData.id; // Adjust this according to your data structure
    console.log("Row Data : ", rowData);
    toggleIsDetailOpened();
    setTransactionId(rowData?._id);
    setName(rowData?.name);
    setNoOfDiamonds(rowData?.diamonds);
    setAccountName(rowData?.name);
    setUsername(rowData?.username);
    setAmountInDollars(rowData?.amountInDollars);
    setDate(moment(rowData?.createdTime).format("DD/MM/YYYY"));
    setPaymentMethod(rowData?.paymentMethod);
    if (rowData?.bankAccountDetails) {
      setAccountNumber(rowData?.bankAccountDetails?.accountNumber);
      setIBAN(rowData?.bankAccountDetails?.iban);
    } else {
      setAccountNumber("");
      setIBAN("");
    }
    setPaymentStatus(rowData?.status);
    setRowData(rowData);

    if (rowData?.status === "Pending") {
      setFirstLineBg("bg-tabColor");
      setFirstLineHeight("h-[1px]");
      setSecondLineBg("bg-tabColor");
      setSecondLineHeight("h-[1px]");
      setPaymentRequestColor("text-active");
      setPaymentRequestBg("bg-white");
      setPaymentRequestBorder("border-active");
      setPaymentRequestChild(<div className="size-3 rounded-full bg-active" />);
      setProcessingRequestColor("text-grey");
      setProcessingRequestBg("bg-white");
      setProcessingRequestBorder("border-hollow");
      setProcessingRequestChild(
        <div className="size-3 rounded-full bg-white" />
      );
      setCompletedRequestColor("text-grey");
      setCompletedRequestBg("bg-white");
      setCompletedRequestBorder("border-hollow");
      setCompletedRequestChild(
        <div className="size-3 rounded-full bg-white" />
      );
    } else if (
      rowData?.status === "Processing" ||
      rowData?.status === "Completed"
    ) {
      setFirstLineBg("bg-active");
      setFirstLineHeight("h-[4px]");
      if (rowData?.status === "Completed") {
        setSecondLineBg("bg-active");
        setSecondLineHeight("h-[4px]");
        setProcessingRequestBg("bg-active");
        setProcessingRequestBorder("border-tab");
        setProcessingRequestChild(
          <img
            className="h-[7.2px] w-[10px]"
            alt="payment-check"
            src={paymentCheck}
          />
        );
        setCompletedRequestColor("text-active");
        setCompletedRequestBg("bg-active");
        setCompletedRequestBorder("border-tab");
        setCompletedRequestChild(
          <img
            className="h-[7.2px] w-[10px]"
            alt="payment-check"
            src={paymentCheck}
          />
        );
      } else {
        setProcessingRequestBg("bg-white");
        setProcessingRequestBorder("border-tab");
        setProcessingRequestChild(
          <div className="size-3 rounded-full bg-active" />
        );
        setCompletedRequestColor("text-grey");
        setCompletedRequestBg("bg-white");
        setCompletedRequestBorder("border-hollow");
        setCompletedRequestChild(
          <div className="size-3 rounded-full bg-white" />
        );
      }
      setPaymentRequestColor("text-active");
      setProcessingRequestColor("text-active");
      setPaymentRequestBg("bg-active");
      setPaymentRequestBorder("border-tab");
      setPaymentRequestChild(
        <img
          className="h-[7.2px] w-[10px]"
          alt="payment-check"
          src={paymentCheck}
        />
      );
    } else if (
      rowData?.status === "Declined" ||
      rowData?.status === "Canceled"
    ) {
      setFirstLineBg("bg-tabColor");
      setFirstLineHeight("h-[1px]");
      setSecondLineBg("bg-tabColor");
      setSecondLineHeight("h-[1px]");
      setPaymentRequestColor("text-declinedOrCancelled");
      setPaymentRequestBg("bg-declinedOrCancelled");
      setPaymentRequestBorder("border-declinedOrCancelled");
      setPaymentRequestChild(
        <img
          className="h-[7.2px] w-[10px]"
          alt="payment-check"
          src={paymentCross}
        />
      );
      setProcessingRequestColor("text-grey");
      setProcessingRequestBg("bg-white");
      setProcessingRequestBorder("border-hollow");
      setProcessingRequestChild(
        <div className="size-3 rounded-full bg-white" />
      );
      setCompletedRequestColor("text-grey");
      setCompletedRequestBg("bg-white");
      setCompletedRequestBorder("border-hollow");
      setCompletedRequestChild(
        <div className="size-3 rounded-full bg-white" />
      );
    } else {
      setFirstLineBg("bg-tabColor");
      setFirstLineHeight("h-[1px]");
      setSecondLineBg("bg-tabColor");
      setSecondLineHeight("h-[1px]");
      setPaymentRequestColor("text-active");
      setPaymentRequestBg("bg-active");
      setPaymentRequestBorder("border-tab");
      setPaymentRequestChild(
        <img
          className="h-[7.2px] w-[10px]"
          alt="payment-check"
          src={paymentCheck}
        />
      );
      setProcessingRequestColor("text-grey");
      setProcessingRequestBg("bg-white");
      setProcessingRequestBorder("border-hollow");
      setProcessingRequestChild(
        <div className="size-3 rounded-full bg-white" />
      );
      setCompletedRequestColor("text-grey");
      setCompletedRequestBg("bg-white");
      setCompletedRequestBorder("border-hollow");
      setCompletedRequestChild(
        <div className="size-3 rounded-full bg-white" />
      );
    }
  };

  const toggleIsDetailOpened = () => {
    setIsDetailOpened(!isDetailOpened);
  };

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  } else {
    return (
      <div style={{ overflow: "auto" }}>
        <Box sx={{ backgroundColor: colors.primary[600], overflow: "auto" }}>
          <Box
            m="1rem 1rem 0px 1rem"
            sx={{ backgroundColor: colors.primary[600] }}
          >
            {isDetailOpened ? (
              <>
                <div className="p-8">
                  {/* back button and approve/decline buttons */}
                  <div className="flex flex-row items-center justify-between">
                    <div
                      onClick={toggleIsDetailOpened}
                      className="flex cursor-pointer flex-row items-center gap-6"
                    >
                      <img
                        className="h-4 w-[0.969rem]"
                        src={backButton}
                        alt="back-button"
                      />
                      <h4 className="text-[1.375rem] font-semibold text-black">
                        {transactionId}
                      </h4>
                    </div>
                    <div className="flex flex-row items-center gap-5">
                      {paymentStatus === "Pending" ? (
                        <>
                          <div className="flex h-10 w-[6.688rem] cursor-pointer  flex-row items-center justify-center rounded border border-tab bg-active px-4">
                            <p
                              onClick={() => approvePayment(rowData)}
                              className="text-sm font-normal text-white"
                            >
                              Approve
                            </p>
                          </div>
                          <div className="flex h-10 w-[6.688rem] cursor-pointer flex-row items-center justify-center rounded border border-inactive bg-white px-4">
                            <p
                              onClick={() => declinePayment(rowData)}
                              className="text-sm font-normal text-inactive"
                            >
                              Decline
                            </p>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            backgroundColor: setBackgroundColor(rowData),
                          }}
                          className="flex h-10 w-[6.688rem] cursor-pointer flex-row items-center justify-center rounded px-4"
                        >
                          <p
                            style={{ color: setColor(rowData) }}
                            className="text-sm font-normal"
                          >
                            {paymentStatus}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Progress line */}
                  <div className="mx-10 mt-16 flex flex-row items-center justify-between">
                    <div className="flex flex-col items-center">
                      <div
                        className={`flex size-5 items-center justify-center rounded-full border ${paymentRequestBorder} ${paymentRequestBg}`}
                      >
                        {paymentRequestChild}
                      </div>
                    </div>
                    <div
                      className={`${firstLineHeight} flex-1 ${firstLineBg}`}
                    />
                    <div className="flex flex-col items-center">
                      <div
                        className={`flex size-5 items-center justify-center rounded-full border ${processingRequestBorder} ${processingRequestBg}`}
                      >
                        {processingRequestChild}
                      </div>
                    </div>
                    <div
                      className={`${secondLineHeight} flex-1 ${secondLineBg}`}
                    />
                    <div className="flex flex-col items-center">
                      <div
                        className={`flex size-5 items-center justify-center rounded-full border ${completedRequestBorder} ${completedRequestBg}`}
                      >
                        {completedRequestChild}
                      </div>
                    </div>
                  </div>
                  {/* Statuses Lin */}
                  <div className="mt-3 flex flex-row items-center justify-between">
                    <p
                      className={`text-base font-medium ${paymentRequestColor}`}
                    >
                      Pending request
                    </p>
                    <p
                      className={`mr-10 text-base font-medium ${processingRequestColor}`}
                    >
                      Processing
                    </p>
                    <p
                      className={`text-base font-medium ${completedRequestColor}`}
                    >
                      Completed
                    </p>
                  </div>
                  <div className="mt-14 flex flex-row items-center justify-between rounded-t-lg bg-headerStrip px-16 py-6">
                    <p className="text-lg font-semibold text-black">
                      Request details
                    </p>
                    <p className="text-lg font-medium text-lightBlack">
                      {transactionId}
                    </p>
                  </div>
                  <div className="bg-white p-16">
                    {/* Row 1 */}
                    <div className="grid grid-cols-3 gap-40">
                      <div>
                        <p className="text-lg font-normal text-grey">Name :</p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {name}
                        </p>
                      </div>
                      <div>
                        <p className="text-lg font-normal text-grey">
                          No. of diamonds :
                        </p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {noOfDiamonds}
                        </p>
                      </div>
                      <div>
                        <p className="text-lg font-normal text-grey">
                          Account Name :
                        </p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {accountName}
                        </p>
                      </div>
                    </div>
                    {/* Row 2 */}
                    <div className="mt-8 grid grid-cols-3 gap-40">
                      <div>
                        <p className="text-lg font-normal text-grey">
                          Username :
                        </p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {username}
                        </p>
                      </div>
                      <div>
                        <p className="text-lg font-normal text-grey">
                          Amount in $ :
                        </p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {amountInDollars}$
                        </p>
                      </div>
                      {accountNumber && (
                        <div>
                          <p className="text-lg font-normal text-grey">
                            Account No. :
                          </p>
                          <p className="mt-2 text-lg font-medium text-lightBlack">
                            {accountNumber}
                          </p>
                        </div>
                      )}
                    </div>
                    {/* Row 3 */}
                    <div className="mt-8 grid grid-cols-3 gap-40">
                      <div>
                        <p className="text-lg font-normal text-grey">Date</p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {date}
                        </p>
                      </div>
                      <div>
                        <p className="text-lg font-normal text-grey">
                          Payment method :
                        </p>
                        <p className="mt-2 text-lg font-medium text-lightBlack">
                          {paymentMethod}
                        </p>
                      </div>
                      {IBAN && (
                        <div>
                          <p className="text-lg font-normal text-grey">
                            IBAN :
                          </p>
                          <p className="mt-2 text-lg font-medium text-lightBlack">
                            {IBAN}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* The Editing Dialog */}
                <Dialog open={open} onClose={handleClose}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DialogTitle fontWeight={"600"} fontSize={"20px"}>
                      Edit {type}
                    </DialogTitle>
                  </Box>
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "30px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      <Box sx={{ marginRight: "35px" }}>
                        <Typography marginBottom={"5px"} fontWeight={"600"}>
                          Name
                        </Typography>
                        <TextField
                          sx={{
                            backgroundColor: colors.blueAccent[100],
                            width: "100%",
                          }}
                          size={"small"}
                          value={stickerData.name}
                          onChange={(e) =>
                            setStickerData({
                              ...stickerData,
                              name: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Typography marginBottom={"5px"} fontWeight={"600"}>
                          Url
                        </Typography>
                        <TextField
                          sx={{
                            backgroundColor: colors.blueAccent[100],
                            width: "100%",
                          }}
                          size={"small"}
                          value={stickerData.url}
                          onChange={(e) =>
                            setStickerData({
                              ...stickerData,
                              url: e.target.value,
                            })
                          }
                        />
                      </Box>
                    </Box>
                    <FormControl
                      sx={{
                        marginRight: "25px",
                        marginLeft: "25px",
                        width: "100%",
                      }}
                    >
                      <Typography marginBottom={"5px"} fontWeight={"600"}>
                        Type
                      </Typography>
                      <Select
                        size={"small"}
                        sx={{ backgroundColor: colors.blueAccent[100] }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stickerData.type}
                        onChange={(e) =>
                          setStickerData({
                            ...stickerData,
                            type: e.target.value,
                          })
                        }
                      >
                        <MenuItem value={`Sticker`}>Sticker</MenuItem>
                        <MenuItem value={`Gif`}>Gif</MenuItem>
                        <MenuItem value={`Emoji`}>Emoji</MenuItem>
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      marginBottom: "25px",
                      marginRight: "35px",
                    }}
                  >
                    {/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
                    <Box>
                      <StyledButton
                        title={`Save`}
                        onClick={() => handleEditSubmit(true)}
                        sx={{
                          padding: "10px 40px",
                          margin: "0px 0px",
                          color: colors.grey[900],
                          backgroundColor: colors.blueAccent[500],
                          "&:hover": {
                            color: colors.grey[900],
                            backgroundColor: colors.blueAccent[900],
                          },
                        }}
                      />
                    </Box>
                  </DialogActions>
                </Dialog>
                {/* The Add Dialog */}
                <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DialogTitle fontWeight={"600"} fontSize={"20px"}>
                      Add {type}
                    </DialogTitle>
                  </Box>
                  <DialogContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "30px",
                          marginLeft: "25px",
                          marginRight: "25px",
                        }}
                      >
                        <Box sx={{ marginRight: "35px" }}>
                          <Typography marginBottom={"5px"} fontWeight={"600"}>
                            Name
                          </Typography>
                          <TextField
                            sx={{
                              backgroundColor: colors.blueAccent[100],
                              width: "100%",
                            }}
                            size={"small"}
                            value={stickerData.name}
                            onChange={(e) =>
                              setStickerData({
                                ...stickerData,
                                name: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box>
                          <Typography marginBottom={"5px"} fontWeight={"600"}>
                            Url
                          </Typography>
                          <TextField
                            sx={{
                              backgroundColor: colors.blueAccent[100],
                              width: "100%",
                            }}
                            size={"small"}
                            value={stickerData.url}
                            onChange={(e) =>
                              setStickerData({
                                ...stickerData,
                                url: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </Box>
                      <Box>
                        {/* <StickerUploader value={stickerData.file} onChange={(e) => setStickerData({ ...stickerData, file: e.target.value })}/> */}
                      </Box>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      marginBottom: "25px",
                      marginRight: "35px",
                    }}
                  >
                    {/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
                    <Box>
                      <StyledButton
                        title={`Save`}
                        onClick={() => addSticker()}
                        sx={{
                          padding: "10px 40px",
                          margin: "0px 0px",
                          color: colors.grey[900],
                          backgroundColor: colors.blueAccent[500],
                          "&:hover": {
                            color: colors.grey[900],
                            backgroundColor: colors.blueAccent[900],
                          },
                        }}
                      />
                    </Box>
                  </DialogActions>
                </Dialog>
                {/* The Delete Dialog */}
                <Dialog
                  open={deleteDialogOpen}
                  onClose={handleDeleteDialogClose}
                >
                  {confirmationDialog === "Approve" ? (
                    <DialogTitle
                      marginTop={"10px"}
                      align="center"
                      fontWeight={"600"}
                      fontSize={"20px"}
                    >
                      {/* Approve {type}? */}
                      Approve Request?
                    </DialogTitle>
                  ) : (
                    <DialogTitle
                      marginTop={"10px"}
                      align="center"
                      fontWeight={"600"}
                      fontSize={"20px"}
                    >
                      {/* Decline {type}? */}
                      Decline Request?
                    </DialogTitle>
                  )}
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginY: "0px",
                        // marginLeft: "25px",
                        // marginRight: "25px",
                      }}
                    >
                      <Typography marginBottom={"5px"} fontWeight={"600"}>
                        {confirmationDialog === "Approve"
                          ? "Are you sure you want to approve this transaction request?"
                          : "Are you sure you want to decline this transaction request?"}
                        {/* <Typography
                      display={"inline"}
                      color={colors.blueAccent[500]}
                    >
                      {" "}
                      {stickerData.id}?{" "}
                    </Typography> */}
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={
                      confirmationDialog !== "Approve"
                        ? {
                            marginBottom: "25px",
                            marginX: "35px",
                            justifyContent: "center",
                          }
                        : { marginBottom: "25px", justifyContent: "center" }
                    }
                  >
                    <Box>
                      {confirmationDialog === "Approve" ? (
                        <StyledButton
                          title={`No, don’t approve`}
                          // onClick={() => handleDisapprove(stickerData)}
                          onClick={() => setDeleteDialogOpen(false)}
                          sx={{
                            // padding: "10px 30px",
                            padding: "10px",
                            margin: "0px 0px",
                            // color: colors.redAccent[500],
                            // backgroundColor: colors.redAccent[900],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.blueAccent[500],
                            backgroundColor: colors.blueAccent[100],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      ) : (
                        <StyledButton
                          title={`No, don’t decline`}
                          // onClick={() => handleDeclineRequest(stickerData)}
                          onClick={() => setDeleteDialogOpen(false)}
                          sx={{
                            padding: "10px",
                            // padding: "10px 40px",
                            margin: "0px 0px",
                            // color: colors.blueAccent[500],
                            // backgroundColor: colors.blueAccent[100],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.redAccent[500],
                            backgroundColor: colors.redAccent[900],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      {confirmationDialog === "Approve" ? (
                        <StyledButton
                          title={`Yes, approve`}
                          onClick={() => {
                            setDeleteDialogOpen(false);
                            handleRequest(withdrawId, "Accept");
                          }}
                          sx={{
                            // padding: "10px 30px",
                            padding: "10px 30px",
                            margin: "0px 0px",
                            // color: colors.redAccent[900],
                            // backgroundColor: colors.redAccent[500],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.blueAccent[100],
                            backgroundColor: colors.blueAccent[500],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      ) : (
                        <StyledButton
                          title={`Yes, decline`}
                          onClick={() => {
                            setDeleteDialogOpen(false);
                            handleRequest(withdrawId, "Decline");
                          }}
                          sx={{
                            padding: "10px 30px",
                            margin: "0px 0px",
                            // color: colors.blueAccent[100],
                            // backgroundColor: colors.blueAccent[500],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.redAccent[900],
                            backgroundColor: colors.redAccent[500],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      )}
                    </Box>
                  </DialogActions>
                </Dialog>
                {/* The Confirmation Dialog */}
                <Dialog
                  open={confirmationDialogOpen}
                  onClose={handleConfirmationDialogClose}
                >
                  <DialogTitle
                    marginTop={"8px"}
                    align="center"
                    fontWeight={"600"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {confirmationDialog === "Approve" ? (
                      <img src={trashIcon} alt="" width={"80vw"} />
                    ) : confirmationDialog === "Add" ? (
                      <img src={addIcon2} alt="" width={"80vw"} />
                    ) : (
                      <img src={restoreIcon2} alt="" width={"80vw"} />
                    )}
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginY: "0px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      <Typography
                        marginBottom={"5px"}
                        fontWeight={"600"}
                        fontSize={"20px"}
                      >
                        {confirmationDialog === "Approve"
                          ? `${confirmationDialog}ed Successfully`
                          : `${confirmationDialog}ed Successfully`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {confirmationDialog === "Approve"
                        ? `Payment ${confirmationDialog}ed for ${stickerData.name}.`
                        : `Payment ${confirmationDialog}ed for ${stickerData.name}.`}
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "25px",
                      marginX: "35px",
                    }}
                  >
                    <Box>
                      <StyledButton
                        title={`Done`}
                        onClick={() => setConfirmationDialogOpen(false)}
                        sx={{
                          padding: "1vh 8vw",
                          margin: "0px 0px",
                          color: colors.primary[900],
                          backgroundColor: colors.blueAccent[500],
                          "&:hover": {
                            color: colors.grey[900],
                            backgroundColor: colors.blueAccent[900],
                          },
                        }}
                      />
                    </Box>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                {/* <TypeSelector onClick={selectTypeHandleSelect} /> */}
                {/* <TypeSelector options={options} onSelect={handleTypeSelect} /> */}
                <h2 className="mt-12 text-[1.375rem] font-medium text-black">
                  Payments
                </h2>
                <div className="my-6 flex flex-row items-center justify-center px-1 py-4">
                  {PAYMENT_TABS.map((tab, index) => (
                    <div
                      className={`cursor-pointer ${
                        currentIndex === index
                          ? "rounded-b-sm border-b-4 border-tab"
                          : "border-b-[0.5px] border-container"
                      }   flex-1 pb-4 text-center`}
                      onClick={() => handleTypeSelect(tab.status, index)}
                    >
                      <h4
                        className={`${
                          currentIndex === index
                            ? "text-active"
                            : "text-tabColor"
                        } text-sm font-normal`}
                      >
                        {tab.title}
                      </h4>
                    </div>
                  ))}
                </div>
                {/* <Header title={`Manage ${type}`} /> */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <SearchBar
                      title={`Search ${status}...`}
                      onSubmit={handleFormSubmit}
                      onChange={handleOnChange}
                      value={search}
                    />
                  </Box>
                  {/* <Box sx={{ marginRight: "15px" }}>
              <StyledButton
                title={`Add ${type}`}
                startIcon={<AddBoxOutlinedIcon />}
                onClick={() => handleAddDialogOpen()}
              />
            </Box> */}
                  <Box>
                    {/* <DropDown onSelect={selectTypeHandleSelect} /> */}
                    {/* <DropDown options={options} onSelect={selectTypeHandleSelect} /> */}
                  </Box>
                </Box>
                <Box>
                  <MaterialTable
                    title="Stickers Table"
                    tableRef={tableRef}
                    columns={columns}
                    data={fetchData}
                    page={page}
                    totalCount={totalCount}
                    onChangeRowsPerPage={(params) =>
                      handlePageSizeChange(params)
                    }
                    onChangePage={(params) => handlePageChange(params)}
                    onRowClick={handleRowClick} // Attach the click event listener to the table rows
                    actions={
                      status === "Pending" && [
                        (rowData) => ({
                          icon: "check",
                          tooltip: "Approve",
                          onClick: (event, rowData) => {
                            approvePayment(rowData);
                          },

                          // disabled: rowData.isDeleted === true,
                        }),
                        (rowData) => ({
                          icon: "close",
                          tooltip: "Decline",
                          onClick: (event, rowData) => {
                            declinePayment(rowData);
                          },
                          // disabled: rowData.isDeleted === false,
                        }),
                        // {
                        //   icon: `close`,
                        //   tooltip: "Decline",
                        //   onClick: (event, rowData) => declinePayment(rowData),
                        // },
                      ]
                    }
                    components={{
                      Toolbar: (props) => {
                        return (
                          <div
                            style={{ backgroundColor: colors.blueAccent[100] }}
                          >
                            <MTableToolbar {...props} />
                          </div>
                        );
                      },
                    }}
                    options={{
                      showTitle: false,
                      paging: true,
                      toolbar: false,
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      maxBodyHeight: "71vh",
                      minBodyHeight: "100%",
                      columnResizable: true,
                      tableLayout: "auto",
                      emptyRowsWhenPaging: false,
                      loadingType: "overlay",
                      pageSizeOptions: [5, 10, 15, 20],
                      pageSize: pageSizeRef.current,
                      headerStyle: {
                        backgroundColor: colors.blueAccent[100],
                        // paddingRight: "2rem",
                        alignItems: "center",
                        color: colors.primary[100],
                        fontWeight: "bold",
                      },
                      rowStyle: {
                        backgroundColor: colors.secondary[900],
                      },
                    }}
                    // editable={{ isDeletable: rowData => (rowData.isDeleted === true) ? false : true, }}
                  />
                </Box>
                {/* The Editing Dialog */}
                <Dialog open={open} onClose={handleClose}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DialogTitle fontWeight={"600"} fontSize={"20px"}>
                      Edit {type}
                    </DialogTitle>
                  </Box>
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "30px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      <Box sx={{ marginRight: "35px" }}>
                        <Typography marginBottom={"5px"} fontWeight={"600"}>
                          Name
                        </Typography>
                        <TextField
                          sx={{
                            backgroundColor: colors.blueAccent[100],
                            width: "100%",
                          }}
                          size={"small"}
                          value={stickerData.name}
                          onChange={(e) =>
                            setStickerData({
                              ...stickerData,
                              name: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Typography marginBottom={"5px"} fontWeight={"600"}>
                          Url
                        </Typography>
                        <TextField
                          sx={{
                            backgroundColor: colors.blueAccent[100],
                            width: "100%",
                          }}
                          size={"small"}
                          value={stickerData.url}
                          onChange={(e) =>
                            setStickerData({
                              ...stickerData,
                              url: e.target.value,
                            })
                          }
                        />
                      </Box>
                    </Box>
                    <FormControl
                      sx={{
                        marginRight: "25px",
                        marginLeft: "25px",
                        width: "100%",
                      }}
                    >
                      <Typography marginBottom={"5px"} fontWeight={"600"}>
                        Type
                      </Typography>
                      <Select
                        size={"small"}
                        sx={{ backgroundColor: colors.blueAccent[100] }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stickerData.type}
                        onChange={(e) =>
                          setStickerData({
                            ...stickerData,
                            type: e.target.value,
                          })
                        }
                      >
                        <MenuItem value={`Sticker`}>Sticker</MenuItem>
                        <MenuItem value={`Gif`}>Gif</MenuItem>
                        <MenuItem value={`Emoji`}>Emoji</MenuItem>
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      marginBottom: "25px",
                      marginRight: "35px",
                    }}
                  >
                    {/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
                    <Box>
                      <StyledButton
                        title={`Save`}
                        onClick={() => handleEditSubmit(true)}
                        sx={{
                          padding: "10px 40px",
                          margin: "0px 0px",
                          color: colors.grey[900],
                          backgroundColor: colors.blueAccent[500],
                          "&:hover": {
                            color: colors.grey[900],
                            backgroundColor: colors.blueAccent[900],
                          },
                        }}
                      />
                    </Box>
                  </DialogActions>
                </Dialog>
                {/* The Add Dialog */}
                <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DialogTitle fontWeight={"600"} fontSize={"20px"}>
                      Add {type}
                    </DialogTitle>
                  </Box>
                  <DialogContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "30px",
                          marginLeft: "25px",
                          marginRight: "25px",
                        }}
                      >
                        <Box sx={{ marginRight: "35px" }}>
                          <Typography marginBottom={"5px"} fontWeight={"600"}>
                            Name
                          </Typography>
                          <TextField
                            sx={{
                              backgroundColor: colors.blueAccent[100],
                              width: "100%",
                            }}
                            size={"small"}
                            value={stickerData.name}
                            onChange={(e) =>
                              setStickerData({
                                ...stickerData,
                                name: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box>
                          <Typography marginBottom={"5px"} fontWeight={"600"}>
                            Url
                          </Typography>
                          <TextField
                            sx={{
                              backgroundColor: colors.blueAccent[100],
                              width: "100%",
                            }}
                            size={"small"}
                            value={stickerData.url}
                            onChange={(e) =>
                              setStickerData({
                                ...stickerData,
                                url: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </Box>
                      <Box>
                        {/* <StickerUploader value={stickerData.file} onChange={(e) => setStickerData({ ...stickerData, file: e.target.value })}/> */}
                      </Box>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      marginBottom: "25px",
                      marginRight: "35px",
                    }}
                  >
                    {/* <Button sx={{ color: colors.greenAccent[500] }} onClick={handleEditSubmit}>Save</Button> */}
                    <Box>
                      <StyledButton
                        title={`Save`}
                        onClick={() => addSticker()}
                        sx={{
                          padding: "10px 40px",
                          margin: "0px 0px",
                          color: colors.grey[900],
                          backgroundColor: colors.blueAccent[500],
                          "&:hover": {
                            color: colors.grey[900],
                            backgroundColor: colors.blueAccent[900],
                          },
                        }}
                      />
                    </Box>
                  </DialogActions>
                </Dialog>
                {/* The Delete Dialog */}
                <Dialog
                  open={deleteDialogOpen}
                  onClose={handleDeleteDialogClose}
                >
                  {confirmationDialog === "Approve" ? (
                    <DialogTitle
                      marginTop={"10px"}
                      align="center"
                      fontWeight={"600"}
                      fontSize={"20px"}
                    >
                      {/* Approve {type}? */}
                      Approve Request?
                    </DialogTitle>
                  ) : (
                    <DialogTitle
                      marginTop={"10px"}
                      align="center"
                      fontWeight={"600"}
                      fontSize={"20px"}
                    >
                      {/* Decline {type}? */}
                      Decline Request?
                    </DialogTitle>
                  )}
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginY: "0px",
                        // marginLeft: "25px",
                        // marginRight: "25px",
                      }}
                    >
                      <Typography marginBottom={"5px"} fontWeight={"600"}>
                        {confirmationDialog === "Approve"
                          ? "Are you sure you want to approve this transaction request?"
                          : "Are you sure you want to decline this transaction request?"}
                        {/* <Typography
                      display={"inline"}
                      color={colors.blueAccent[500]}
                    >
                      {" "}
                      {stickerData.id}?{" "}
                    </Typography> */}
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={
                      confirmationDialog !== "Approve"
                        ? {
                            marginBottom: "25px",
                            marginX: "35px",
                            justifyContent: "center",
                          }
                        : { marginBottom: "25px", justifyContent: "center" }
                    }
                  >
                    <Box>
                      {confirmationDialog === "Approve" ? (
                        <StyledButton
                          title={`No, don’t approve`}
                          // onClick={() => handleDisapprove(stickerData)}
                          onClick={() => setDeleteDialogOpen(false)}
                          sx={{
                            // padding: "10px 30px",
                            padding: "10px",
                            margin: "0px 0px",
                            // color: colors.redAccent[500],
                            // backgroundColor: colors.redAccent[900],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.blueAccent[500],
                            backgroundColor: colors.blueAccent[100],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      ) : (
                        <StyledButton
                          title={`No, don’t decline`}
                          // onClick={() => handleDeclineRequest(stickerData)}
                          onClick={() => setDeleteDialogOpen(false)}
                          sx={{
                            padding: "10px",
                            // padding: "10px 40px",
                            margin: "0px 0px",
                            // color: colors.blueAccent[500],
                            // backgroundColor: colors.blueAccent[100],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.redAccent[500],
                            backgroundColor: colors.redAccent[900],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      {confirmationDialog === "Approve" ? (
                        <StyledButton
                          title={`Yes, approve`}
                          onClick={() => {
                            setDeleteDialogOpen(false);
                            handleRequest(withdrawId, "Accept");
                          }}
                          sx={{
                            // padding: "10px 30px",
                            padding: "10px 30px",
                            margin: "0px 0px",
                            // color: colors.redAccent[900],
                            // backgroundColor: colors.redAccent[500],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.blueAccent[100],
                            backgroundColor: colors.blueAccent[500],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      ) : (
                        <StyledButton
                          title={`Yes, decline`}
                          onClick={() => {
                            setDeleteDialogOpen(false);
                            handleRequest(withdrawId, "Decline");
                          }}
                          sx={{
                            padding: "10px 30px",
                            margin: "0px 0px",
                            // color: colors.blueAccent[100],
                            // backgroundColor: colors.blueAccent[500],
                            // "&:hover": {
                            //   color: colors.grey[900],
                            //   backgroundColor: colors.blueAccent[900],
                            // },
                            color: colors.redAccent[900],
                            backgroundColor: colors.redAccent[500],
                            "&:hover": {
                              color: colors.grey[900],
                              backgroundColor: colors.blueAccent[900],
                            },
                          }}
                        />
                      )}
                    </Box>
                  </DialogActions>
                </Dialog>
                {/* The Confirmation Dialog */}
                <Dialog
                  open={confirmationDialogOpen}
                  onClose={handleConfirmationDialogClose}
                >
                  <DialogTitle
                    marginTop={"8px"}
                    align="center"
                    fontWeight={"600"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {confirmationDialog === "Approve" ? (
                      <img src={trashIcon} alt="" width={"80vw"} />
                    ) : confirmationDialog === "Add" ? (
                      <img src={addIcon2} alt="" width={"80vw"} />
                    ) : (
                      <img src={restoreIcon2} alt="" width={"80vw"} />
                    )}
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginY: "0px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      <Typography
                        marginBottom={"5px"}
                        fontWeight={"600"}
                        fontSize={"20px"}
                      >
                        {confirmationDialog === "Approve"
                          ? `${confirmationDialog}ed Successfully`
                          : `${confirmationDialog}ed Successfully`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {confirmationDialog === "Approve"
                        ? `Payment ${confirmationDialog}ed for ${stickerData.name}.`
                        : `Payment ${confirmationDialog}ed for ${stickerData.name}.`}
                    </Box>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "25px",
                      marginX: "35px",
                    }}
                  >
                    <Box>
                      <StyledButton
                        title={`Done`}
                        onClick={() => setConfirmationDialogOpen(false)}
                        sx={{
                          padding: "1vh 8vw",
                          margin: "0px 0px",
                          color: colors.primary[900],
                          backgroundColor: colors.blueAccent[500],
                          "&:hover": {
                            color: colors.grey[900],
                            backgroundColor: colors.blueAccent[900],
                          },
                        }}
                      />
                    </Box>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Box>
        </Box>
      </div>
    );
  }
}
export default Payments;
