import { Box, Typography } from '@mui/material';
// import { tokens } from '../theme';
// import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase, textColor }) => {
	// const theme = useTheme();
	// const colors = tokens(theme.palette.mode);

	return (
		<Box width='100%' m='0 30px'>
			<Box display='flex' alignItems='center'>
				<Box marginRight={'16px'}>{icon}</Box>
				<Box>
					<Typography variant='h4' fontWeight='bold' color={textColor}>
						{title}
					</Typography>
					<Box display='flex' flexDirection='column' mt='2px'>
						<Typography variant='h5' color={textColor}>
							{subtitle}
						</Typography>
						<Typography variant='h5' fontStyle='italic' color={textColor}>
							{increase}
						</Typography>
					</Box>
				</Box>
				{/* <Box>
					<ProgressCircle progress={progress} />
				</Box>{' '} */}
			</Box>
		</Box>
	);
};

export default StatBox;
