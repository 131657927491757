import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { tokens } from '../../theme';
import { useLoginStore } from '../../store/index';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/seezittNewLogoClip.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './index.css';

export default function Login() {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const API_KEY = process.env.REACT_APP_API_KEY;
	const endPoint = 'Auth/Login';
	const urlPost = `${API_KEY}/${endPoint}`;
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	function handleSubmit(event) {
		// prevent default form submission behavior
		event.preventDefault();
		// handle form submission
		console.log(username, password);
		setIsLoading(true);
		handleLogin(username, password);
		// retrieveProfile();
	}

	const handleLogin = async (username, password) => {
		try {
			const response = await fetch(urlPost, {
				method: 'POST',
				headers: { 'Content-type': 'application/json' },
				body: JSON.stringify({ username, password }),
			});
			if (response.ok) {
				const responseData = await response.json();
				const { token } = responseData.data; // Extract token value from data object
				useLoginStore.setState({
					isLoggedIn: true,
					username: username,
					token: token,
				});

				navigate('/dashboard');
			} else {
				useLoginStore.setState({ isLoggedIn: false, mode: 'light' });
				navigate('/login');
				setErrorMessage('Invalid username or password');
				setIsLoading(false);
				console.log(response);
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			setErrorMessage('Invalid username or password');
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box
				sx={{
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#574D68',
					// backgroundSize: 'cover',
					backgroundImage: `url(${backgroundImage})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				}}>
				<Box
					sx={{
						// marginTop: '  px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: `#fff`,
						borderRadius: '8px',
						padding: '40px 50px ',
						width: '30vw',
					}}>
					<Typography fontWeight={'700'} fontSize={'25px'} color={'#574D68'} margin={'20px 20px 10px 20px'}>
						Login to admin portal
					</Typography>
					<Box component='form' sx={{ mt: '20px' }}>
						{isLoading ? (
							<span className='loader'></span>
						) : errorMessage ? (
							<Typography fontWeight={'700'} fontSize={'16px'} color={'red'} margin={'10px 20px'}>
								{errorMessage}
							</Typography>
						) : null}
					</Box>

					<Box component='form' sx={{ mt: '20px' }}>
						<TextField
							variant='outlined'
							margin='dense'
							required
							fullWidth
							id='username'
							label='Username'
							name='username'
							autoComplete='username'
							autoFocus
							color='info'
							size='small'
							onChange={(e) => setUsername(e.target.value)}
						/>
						<TextField
							variant='outlined'
							margin='normal'
							required
							fullWidth
							name='password'
							label='Password'
							type={showPassword ? 'text' : 'password'}
							id='password'
							autoComplete='current-password'
							color='info'
							size='small'
							onChange={(e) => setPassword(e.target.value)}
							InputProps={{
								endAdornment: (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											cursor: 'pointer',
										}}
										onClick={() => handleClickShowPassword()}>
										{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
									</Box>
								),
							}}
						/>
						<Grid display={'flex'} justifyContent={'end'}>
							<Grid>
								<Link href='#' underline='true' sx={{ color: colors.blueAccent[500] }}>
									Forgot password?
								</Link>
							</Grid>
						</Grid>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{
								mt: '20px',
								mb: '10px',
								padding: '10px',
								color: colors.primary[500],
								backgroundColor: colors.blueAccent[500],
								'&:hover': {
									backgroundColor: colors.greenAccent[700],
									color: colors.primary[100],
								},
							}}
							onClick={handleSubmit}>
							Login
						</Button>
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	);
}
