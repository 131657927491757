import { Button, useTheme } from '@mui/material';
import { tokens } from '../theme';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export default function DropDown({ options, onSelect, title }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(null);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const open = Boolean(anchorEl);

	const handleClickListItem = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event, index) => {
		console.log('selected', options[index]);
		setSelectedIndex(index);
		onSelect(options[index].value);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				variant='outlined'
				endIcon={<KeyboardArrowDownOutlinedIcon />}
				sx={{
					padding: '8px 8px',
					margin: '0px 0px',
					height: '45px',
					color: colors.grey[100],
					// backgroundColor: colors.blueAccent[100],
					backgroundColor: 'transparent',
					'&:hover': {
						color: colors.grey[100],
						backgroundColor: colors.blueAccent[800],
					},
				}}
				onClick={handleClickListItem}>
				Sort By {title}: {options[selectedIndex]?.label}
			</Button>
			<Menu id='lock-menu' anchorEl={anchorEl} open={open} onClose={handleClose}>
				{options.map((option, index) => (
					<MenuItem
						key={option.value}
						selected={index === selectedIndex}
						onClick={(event) => handleMenuItemClick(event, index)}>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
